<template>
	<div class="w-full flex flex-no-wrap items-stretch" :class="{ 'justify-center': inline }">
		<div v-if="! inline && ! noLeft" class="flex">
			<span class="flex items-center leading-normal bg-gray-200 rounded rounded-r-none border border-r-0 border-gray-400 px-3 whitespace-no-wrap text-gray-600 text-sm">
				<fa class="s-calendar-alt" />
			</span>
		</div>
		<div v-if="inline" class="datepicker" />
		<input
      v-else
      ref="dp"
      :disabled="disabled"
      type="text"
      class="flex-shrink flex-grow md:flex-grow-0 md:w-24 flex-auto border border-gray-400 rounded rounded-l-none p-1 datepicker text-center"
      :class="{ 'rounded-r-none': noRight }"
      @blur="blurEventFunction"
      @keyup.enter="keyupEnterEventFunction"
    >
	</div>
</template>

<script>
export default {
	name: 'DatePickerNew',
	model: {
		prop: 'model',
		event: 'change'
	},
	props: {
		model: {
			type: String,
			default: undefined
		},
		calendar: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		startDate: {
			type: String,
			default: null
		},
		maxToday: {
			type: Boolean,
			default: false
		},
		inline: {
			type: Boolean,
			default: false
		},
		noLeft: {
			type: Boolean,
			default: false
		},
		noRight: {
			type: Boolean,
			default: false
		}
	},
  data:() => ({
    reset: false
  }),
	computed: {
		lang() {
			return this.$parent.lang
		},
		style() {
			if (! this.calendar) {
				return {
					'width': '90px',
					'min-width': '90px',
					'margin-left': '-5px'
				}
			}
			else {
				return {
					'width': '130px',
					'min-width': '130px'
				}
			}
		}
	},
	watch: {
		lang() {
			this.refreshDatePicker()
		},
		model(newVal) {
      // console.log('reset', newVal, '#')
      if (newVal == null) {
        this.clearDate()
      }
			if (! this.inline) {
				// $(this.$refs.dp).datepicker('setDate', val)
        // console.log('watch triggered', newVal)
        this.dateChecker()
			}
		},
		startDate(startDate) {
			if (startDate != '') {
				var momentThis = moment($(this.$refs.dp).datepicker('getDate'))
				var momentStart = moment(startDate, 'DD/MM/YYYY')

				$(this.$refs.dp).datepicker('setStartDate', startDate)

				if (momentStart > momentThis) {
					$(this.$refs.dp).datepicker('setDate', startDate)
				}
			}
		}
	},
	methods: {
		openDatePicker() {
			if (this.disabled) { return }
			$(this.$refs.dp).datepicker('show')
		},
		refreshDatePicker() {
			$(this.$refs.dp).datepicker('remove')
			this.initDatePicker()
		},
		initDatePicker() {
			var self = this
			$(document).ready(() => {

				$.fn.datepicker.dates['it'] = {
					days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
					daysShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
					daysMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
					months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
					monthsShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
					today: 'Oggi',
					monthsTitle: 'Mesi',
					clear: 'Cancella',
					weekStart: 1,
					format: 'dd/mm/yyyy',
				};

				// Datepicker
				$(this.$refs.dp).datepicker({
					autoclose: true,
					clearBtn: ! this.inline,
					format: 'dd/mm/yyyy',
					weekStart: 1,
					language: this.lang,
					startDate: this.startDate != null ? this.startDate : null,
					endDate: this.maxToday ? '0d' : false,
					todayHighlight: true,
          forceParse: false,
				})
				.on('changeDate', function (event) {
					// console.log('-=x changeDate x=-')
          let inputValue = event.target.value
          if (inputValue.length > 0 && inputValue.length < 10) {
            // console.log('date length invalid');
            return
          }
          self.dateChecker()
				})
				.on('clearDate', function (event) {
					// console.log('-=x clearDate x=-')
					self.reset = true
				})

        self.dateChecker()
			})
		},
    setDate(inputValue) {
      // console.log('✅', 'setDatePicker', inputValue)
      let dp_instance = $(this.$refs.dp)
      dp_instance.val(inputValue).datepicker('update')
      this.$emit('change', inputValue)
    },
    clearDate() {
      // console.log('❌', 'clearDatePicker')
      let today = moment().format('DD/MM/YYYY')
      let dp_instance = $(this.$refs.dp)
      dp_instance.val(today).datepicker('update')
      dp_instance.val('').datepicker('update')
      this.$emit('change', null)
    },
    dateChecker(params) {
      // console.log('-=x dateChecker x=-')

      let inputValueParams = params?.inputValue
      let inputValueRef = this.$refs.dp.value
      let inputValue = inputValueParams || inputValueRef
      let dp_instance = $(this.$refs.dp)
      let datePickerValue = dp_instance.datepicker('getFormattedDate')
      let modelValue = this.model

      // console.log('inputValueParams', inputValueParams)
      // console.log('inputValueRef', inputValueRef)
      // console.log('datePickerValue', datePickerValue)
      // console.log('modelValue', modelValue)
      // console.log('reset', this.reset)
      // console.log('~~~~~~~~~~~~~~~~~~')

      if (
        inputValueParams == inputValueRef &&
        inputValueParams == datePickerValue &&
        inputValueParams == modelValue
      ) {
        // console.log('TUTTI UGUALI')
        return false
      }

      // // console.log(params?.inputValue == this.$refs.dp.value)
      // if (params?.inputValue && params?.inputValue == this.$refs.dp.value) {
      //   // console.log('skip blur ... continue')
      //   if (params?.inputValue?.length < 10) {
      //     this.clearDate()
      //     return true
      //   }
      // }
      // else if (params?.inputValue && params?.inputValue != this.$refs.dp.value) {
      //   // console.log('==========')
      //   // console.log('inputvalues are different')
      //   // console.log(params?.inputValue)
      //   // console.log(this.$refs.dp.value)
      //   // console.log('==========')
      //   return false
      // }

      // STOP WHEN RESET ON CLEAR
      if (inputValue == '' && datePickerValue == '' && modelValue == null) {
        // console.log('FORCE CLEAR ... STOP')
        // console.log('================')
        this.reset = false
        return
      }

      // RESET ON CLEAR
      if (this.reset) {
        // console.log('FORCE CLEAR')
        this.clearDate()
        // console.log('================')
        return
      }

      // if (inputValue.length > 0 && inputValue.length < 10) { return }

      // VALUE SET ON MOUNTED
      if (! inputValue && ! datePickerValue && modelValue) {
        // console.log('→→→ value set on mounted', modelValue)
        // console.log('→→→ triggered changeDate')
        // console.log('###################')
        dp_instance.val(modelValue).datepicker('update')
        return
      }

      if (! inputValue || inputValue.length < 10 || moment(inputValue, 'DD/MM/YYYY').format('YYYY') < 1900 || moment(inputValue, 'DD/MM/YYYY').format('YYYY') > 2099) {
        // console.log('here before clearDate')
				this.clearDate()
      }

      else {
        this.setDate(inputValue)
      }
    },
    blurEventFunction(e) {
      // console.log('•••••••••• blurEventFunction', e.target.value)
      this.dateChecker({ inputValue: e.target.value })
    },
    keyupEnterEventFunction(e) {
      this.reset = false
    }
	},
	mounted() {
    // console.log('mounted')
    // console.log(this.model)

    new Cleave(this.$refs.dp, {
      date: true,
      datePattern: ['d', 'm', 'Y'],
    })
		this.initDatePicker()
	}
}
</script>
