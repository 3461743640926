import axios from 'axios'

const client = axios.create({
	json: true,
	withCredentials: true
})

class ResponseObject {
	constructor(status, statusCode, statusText, data, errors) {
		this.status = status
		this.statusCode = statusCode
		this.statusText = statusText
		this.data = data

		// dbg(status, '► status ◄', 'red', 'white')
		// dbg(statusCode, '► statusCode ◄', 'red', 'white')
		// dbg(statusText, '► statusText ◄', 'red', 'white')
		// dbg(data, '► data ◄', 'red', 'white')
		// dbg(errors, '► errors ◄', 'red', 'white')

		var errorsObj = errors
		var errorsHtml = null
		if (errorsObj) {
			errorsHtml = '<br><ul>'
			Object.keys(errorsObj).forEach(k => {
				console.log()
				errorsObj[k].forEach(v => {
					errorsHtml += `<li>• ${v}</li>`
				})
			})
			// var errorsHtml = '<pre style="background-color:transparent;border:none">' + JSON.stringify(errors, null, 1) + '</pre>'
			errorsHtml += '</ul>'
		}
		this.errorsObj = errorsObj
		this.errorsHtml = errorsHtml

		if (! status && ! errorsHtml) {
			snackbar(statusText, 'red')
			// dbg(data, 'data')
			// dbg(statusCode, 'statusCode')
			// dbg(statusText, 'statusText')
			// dbg(errors, 'errors')
			notifyE(statusText)
		}
		if (errorsHtml) {
			snackbar(errorsHtml, 'red')
			notifyE(errorsHtml)
		}
	}
}

function b64EncodeUnicode(str) {
	// first we use encodeURIComponent to get percent-encoded UTF-8,
	// then we convert the percent encodings into raw bytes which
	// can be fed into btoa.
	return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
		function toSolidBytes(match, p1) {
			return String.fromCharCode('0x' + p1);
	}));
}
window.b64EncodeUnicode = b64EncodeUnicode

export default {
	async internalCall(method, url, data) {
		// put, xxxx, {}
		try {
			if (url.includes('api.')) {
				url = route(url).toString()
			}

			if (! url.includes(location.origin)) {
				url = location.origin + url
			}

			url = new URL(url)

			// se il metodo è "get"...
			if (method === 'get') {
				// console.log(method)

				// ... se è presente un "payload" di dati ...
				if (data) {
					// console.log(data)

					// ... se contiene un parametro "paginate", ne ricava le proprietà "page -> currentPage" e "perPage" accodandole alla querystring
					if (data.hasOwnProperty('params') && data.params.hasOwnProperty('paginate')) {
						// console.log(data.params.paginate)
						url.searchParams.set('page', data.params.paginate.current_page)
						url.searchParams.set('perPage', data.params.paginate.per_page)
						delete data.params['paginate']
					}
					
					// ... codifica il payload in base64 nel parametro "q" della querystring
					url.searchParams.set('q', b64EncodeUnicode(JSON.stringify(data)))
				}
				data = null
			}

			let promise = await client({
				method,
				url,
				data
			})

			return new ResponseObject(true, promise.status, promise.statusText, promise.data)
		}
		catch (error) {

			// error w/o response
			if (! error.response) { 
				var errorObject = JSON.parse(JSON.stringify(error))
				dbg(errorObject, 'ERROR w/o response', 'red')
				if (errorObject.code && errorObject.code === 'ECONNABORTED') {
					// notifyE('Network issue: timeout occurred!')
					return new ResponseObject(false, null)
				}
				console.log(error)
				return new ResponseObject(false, null, 'Network error!<br>' + error)
			}
	
			// error w/ response
			else if (error.response) {
				// throw error.response
				// return
				dbg('ERROR w/ response', null, 'firebrick')
				let { status, statusText, data } = error.response
				dbg(data, 'ERROR DATA')

				if (status == 401 && (location.href.includes('/booking') || location.href.includes('/admin'))) {
					notifyI('Sessione non attiva. Reindirizzamento al login...')
					setTimeout(x => location.href = '/login', 2000)					
					return
				}

				if (data.hasOwnProperty('message')) {
					statusText += '<br>' + data.message
				}
					
				// UNAUTHENTICATED
				if (status == 401) {
					$userReset()
					// necessario raggiungere this.clear() del componente Login.vue per svuotare l'oggetto tokens
				}
				if (status == 419) {
					dbg(error, '-= ERROR =-')
				}
				if (typeof error.response.data == 'string' && error.response.data.includes('Proxy error')) {
					// notifyE('Network issue: server unreachable!')
				}
				// else {
				//     let formattedErrors = error.response.data.errors ? '<pre>' + JSON.stringify(error.response.data.errors, undefined, 2) + '</pre>' : null
				//     dbg(formattedErrors, '-= formattedErrors =-')
				//     // notifyE(error.response.statusText + error.response.data.message + (formattedErrors || ''))
				// }
				return new ResponseObject(false, status, statusText, null, error.response.data.errors)
			}
		}
	},
	get(url, payload) {
		let execution = this.internalCall('get', url, payload)
		return execution
	},
	post(url, payload) {
		let execution = this.internalCall('post', url, payload)
		return execution
	},
	put(url, payload) {
		let execution = this.internalCall('put', url, payload)
		return execution
	},
	patch(url, payload) {
		let execution = this.internalCall('patch', url, payload)
		return execution
	},
	delete(url, payload) {
		let execution = this.internalCall('delete', url, payload)
		return execution
	}
}
