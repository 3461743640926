<template>
  <div class="hidden md:block" style="min-height:450px">
		<section id="preloader-rs" class="relative flex justify-center items-center" style="height:449px;top:64px; background: url('/img/revolution-slider/slider1700x449_01.jpg') center center">
			<fa class="s-asterisk fa-spin text-6xl text-white" />
		</section>
		<section id="revo-slider" class="hidden revolution-slider relative" style="top:64px;z-index:1">
			<div class="bannercontainer">
				<div class="banner">
					<ul>
						<!-- SLIDE 1 -->
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on">
							<img alt="" src="/img/revolution-slider/slider1700x449_01.jpg" />

							<div class="caption customin rs-parallaxlevel-0"
								data-x="70"
								data-y="40"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="1850"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-family: Calibri;font-size:32px; font-weight:bold;color:#fff;text-shadow: 2px 2px rgba(0,0,0,0.75) !important;">
								{{ locale.slide_01_01a }}
							</div>
							<div class="caption customin rs-parallaxlevel-0"
								data-x="70"
								data-y="80"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="2050"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-family: Calibri;font-size:32px; font-weight:bold;color:#fff;text-shadow: 2px 2px rgba(0,0,0,0.75) !important;">
								{{ locale.slide_01_01b }}
							</div>
							<div class="caption customin rs-parallaxlevel-0"
								data-x="70"
								data-y="120"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="2250"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-family: Calibri;font-size:32px; font-weight:bold;color:#fff;text-shadow: 2px 2px rgba(0,0,0,0.75) !important;">
								{{ locale.slide_01_01c }}
							</div>
							<div class="caption lfl randomrotateout rs-parallaxlevel-7"
								data-delay="1"
								:data-x="locale.slide_01_01_x"
								data-y="120"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="800"
								data-start="2250"
								data-easing="Power3.easeInOut"
								data-elementdelay="0.1"
								data-endelementdelay="0"
								data-endspeed="600"
								style="z-index:1;">
								<img src="/js/plugins/rs-plugin/images/dummy.png" alt="" data-lazyload="/img/revolution-slider/move/rombo_rosso_3.png">
							</div>

							<div class="caption small_light_white fadeout rs-parallaxlevel-0"
								data-x="90"
								data-y="310"
								data-speed="500"
								data-start="2600"
								data-easing="Power3.easeInOut"
								data-splitin="chars"
								data-splitout="none"
								data-elementdelay="0.03"
								data-endelementdelay="0.1"
								style="z-index: 5; max-width: auto; max-height: auto; white-space: nowrap; font-family: Trebuchet MS; font-size: 18px;">
								{{ locale.slide_01_02a }}
							</div>
							<div class="caption small_light_white sfl fadeout rs-parallaxlevel-0"
								data-x="225"
								data-y="340"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="3500"
								data-easing="Power3.easeInOut"
								data-splitin="chars"
								data-splitout="none"
								data-elementdelay="0.03"
								data-endelementdelay="0.1"
								style="z-index: 5; max-width: auto; max-height: auto; white-space: nowrap; font-family: Trebuchet MS; font-size: 18px;">
								{{ locale.slide_01_02b }}
							</div>
							<div class="caption small_light_white customin rs-parallaxlevel-0"
								data-x="500"
								data-y="360"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="3800"
								data-easing="Power3.easeInOut"
								data-splitin="chars"
								data-splitout="none"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								style="z-index: 5; max-width: auto; max-height: auto; white-space: nowrap; font-family: Calibri; font-style:italic;">
								(Marcel Proust)
							</div>
						</li>

						<!-- SLIDE 2 -->
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on">
							<img alt="" src="/img/revolution-slider/slider1700x449_02.jpg" />

							<div class="tp-caption sft customout"
								data-x="60" data-hoffset="134"
								data-y="150" data-voffset="-6"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:5;scaleY:5;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0.75;scaleY:0.75;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="600"
								data-start="1000"
								data-easing="Power4.easeOut"
								data-endspeed="500"
								data-endeasing="Power4.easeOut"
								data-autoplay="true"
								data-autoplayonlyfirsttime="false"
								data-nextslideatend="true">
								<video muted autoplay class="video-js vjs-default-skin" preload="none" width="320" height="180"
										data-setup="{}">
									<source src="/img/Filmato320x180.mp4" type='video/mp4' />
									<track kind="captions" src="demo.captions.vtt" srclang="en" label="English" />
								</video>
							</div>

							<div class="caption small_light_white customin rs-parallaxlevel-0"
								data-x="60"
								data-y="48"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="1850"
								data-easing="Power3.easeInOut"
								data-splitin="chars"
								data-splitout="none"
								data-elementdelay="0.05"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-family: Calibri;font-size:32px; font-weight:bold;color:#fff;text-shadow: 2px 2px rgba(0,0,0,0.75) !important;"
								v-html="locale.slide_02_01" />
							<div class="caption customin randomrotateout rs-parallaxlevel-7"
								data-x="120"
								data-y="60"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:0% 0%;"
								data-speed="1000"
								data-start="2300"
								data-easing="Power3.easeInOut"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								data-endspeed="600"
								style="z-index: 6;">
								<img src="/js/plugins/rs-plugin/images/dummy.png" alt="" data-lazyload="/img/revolution-slider/move/linea.png" style="width:64%">
							</div>

							<div class="caption small_light_white customin rs-parallaxlevel-0"
								data-x="800"
								data-y="250"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="2800"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.03"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-family: Calibri;font-size:20px; font-weight:bold;color:#fff;"
								v-html="locale.slide_02_02"
							/>
							<div class="caption small_light_white customin rs-parallaxlevel-0"
								data-x="1000"
								data-y="300"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="2900"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-family: Calibri;font-size:18px; font-style:italic;color:#fff;">
								(John Steinbeck)
							</div>
						</li>

						<!-- SLIDE 3 -->
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on">
							<img alt="" src="/img/revolution-slider/slider1700x449_03.jpg" />

							<div class="caption lfl carousel-caption" data-x="150" data-y="-50" data-speed="3000" data-start="1000" data-easing="easeOutBack">
								<img alt="" src="/img/revolution-slider/move/elicottero-118.png" />
							</div>
							<div class="caption sft rs-parallaxlevel-0"
								data-x="615"
								:data-y="locale.slide_03_01_y"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="1000"
								data-start="1400"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-family: Calibri;font-size:32px; font-weight:bold;color:#fff;text-shadow: 2px 2px rgba(0,0,0,0.75) !important;"
								v-html="locale.slide_03_01"
							/>
							<div class="caption lfl carousel-caption" data-x="950" :data-y="locale.slide_03_02_y" data-speed="1500" data-start="2000" data-easing="easeOutBack">
								<img alt="" src="/img/revolution-slider/move/118.png" style="width:100px" />
							</div>
							<div class="caption sfl carousel-caption" data-x="650" data-y="250" data-speed="1500" data-start="3000" data-easing="easeOutBack">
								<a id="logo-alilife" target="_blank" class="hide" href="http://www.alilife.it"><img alt="" src="/img/revolution-slider/move/alilife.png" /><br /><span style="color:#fff;font-weight:bold" class="pull-right">www.alilife.it</span></a>
							</div>
						</li>

						<!-- SLIDE 4 -->
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on">
							<img alt="" src="/img/revolution-slider/slider1700x449_04.jpg" />
							<div class="caption lft carousel-caption" data-x="0" data-y="40" data-speed="6000" data-start="0" data-easing="easeOutBack" style="width: 200px;">
								<img alt="" src="/img/revolution-slider/move/elicottero-vista-laterale.png" />
							</div>
							<div class="caption lfr carousel-caption" data-x="670" data-y="20" data-speed="3000" data-start="2600" data-easing="easeOutBack" style="width: 200px;">
								<img alt="" src="/img/revolution-slider/move/cockpit-3.png" style="width:150px" />
							</div>
							<div class="caption lfr carousel-caption" data-x="950" data-y="75" data-speed="3000" data-start="2800" data-easing="easeOutBack" style="width: 200px;">
								<img alt="" src="/img/revolution-slider/move/cockpit-2.png" style="width:150px" />
							</div>
							<div class="caption lfr carousel-caption" data-x="830" data-y="190" data-speed="3000" data-start="3000" data-easing="easeOutBack" style="width: 200px;">
								<img alt="" src="/img/revolution-slider/move/cockpit-1.png" style="width:150px" />
							</div>
							<div class="caption red_bold_bg_20 customin rs-parallaxlevel-0"
								data-x="60"
								data-y="30"
								data-customin="x:330;y:0;z:0;rotationX:100;rotationY:100;rotationZ:0;scaleX:1;scaleY:1;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="2500"
								data-start="300"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.2"
								data-endelementdelay="0.1"
								style="color: #fff; z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-size:28px; font-family: Calibri; font-size:27px; font-weight:bold; background-color:rgba(17,77,139,0.8); padding:0 10px">
								{{ locale.slide_04_01 }}
							</div>

							<div class="caption customin randomrotateout rs-parallaxlevel-7"
								data-x="360"
								data-y="332"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="300"
								data-start="1900"
								data-easing="Power3.easeInOut"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								data-endspeed="600"
								style="z-index: 6;">
								<img src="/js/plugins/rs-plugin/images/dummy.png" alt="" data-lazyload="/img/revolution-slider/move/rombo_rosso.png">
							</div>
							<div class="caption small_light_white customin rs-parallaxlevel-0"
								data-x="370"
								data-y="340"
								data-customin="x:0;y:0;z:0;rotationX:-500;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="1850"
								data-easing="Power3.easeInOut"
								data-splitin="chars"
								data-splitout="none"
								data-elementdelay="0.05"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-size:28px; font-family: Calibri; font-size:27px; font-weight:bold;-ms-transform:rotate(-7deg);-ms-transform:rotate(-7deg);-webkit-transform:rotate(-50deg) !important;transform:rotate(-7deg);">
								P.P.L.(H)
							</div>

							<div class="caption customin randomrotateout rs-parallaxlevel-7"
								data-x="500"
								data-y="332"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="300"
								data-start="2300"
								data-easing="Power3.easeInOut"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								data-endspeed="600"
								style="z-index: 6;">
								<img src="/js/plugins/rs-plugin/images/dummy.png" alt="" data-lazyload="/img/revolution-slider/move/rombo_rosso.png">
							</div>
							<div class="caption small_light_white customin rs-parallaxlevel-0"
								data-x="510"
								data-y="340"
								data-customin="x:0;y:0;z:0;rotationX:-500;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="2250"
								data-easing="Power3.easeInOut"
								data-splitin="chars"
								data-splitout="none"
								data-elementdelay="0.05"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-size:28px; font-family: Calibri; font-size:27px; font-weight:bold;-ms-transform:rotate(-7deg);-ms-transform:rotate(-7deg);-webkit-transform:rotate(-50deg) !important;transform:rotate(-7deg);">
								C.P.L.(H)
							</div>

							<div class="caption customin randomrotateout rs-parallaxlevel-7"
								data-x="640"
								data-y="332"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="300"
								data-start="2700"
								data-easing="Power3.easeInOut"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								data-endspeed="600"
								style="z-index: 6;">
								<img src="/js/plugins/rs-plugin/images/dummy.png" alt="" data-lazyload="/img/revolution-slider/move/rombo_rosso.png">
							</div>
							<div class="caption small_light_white customin rs-parallaxlevel-0"
								data-x="650"
								data-y="340"
								data-customin="x:0;y:0;z:0;rotationX:-500;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="500"
								data-start="2650"
								data-easing="Power3.easeInOut"
								data-splitin="chars"
								data-splitout="none"
								data-elementdelay="0.05"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-size:28px; font-family: Calibri; font-size:27px; font-weight:bold;-ms-transform:rotate(-7deg);-ms-transform:rotate(-7deg);-webkit-transform:rotate(-50deg) !important;transform:rotate(-7deg);">
								A.T.P.(H)
							</div>
						</li>

						<!-- SLIDE 5 -->
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on">
							<img alt="" src="/img/revolution-slider/slider1700x449_05.jpg" />
							<div class="caption red_bold_bg_20 sfl rs-parallaxlevel-0"
								data-x="0"
								data-y="15"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="1000"
								data-start="1400"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								style="color: #fff; z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-size:28px; font-family: Calibri; font-size:27px; font-weight:bold; background-color:rgba(53,170,71,0.7); padding:0 10px">
								{{ locale.slide_05_01 }}
							</div>
							<div class="caption lfr carousel-caption" data-x="650" data-y="280" data-speed="3000" data-start="1000" data-easing="easeOutBack" style="width: 200px;">
								<img alt="" src="/img/revolution-slider/move/Excellent_Leonardo.jpg" />
							</div>
						</li>

						<!-- SLIDE 6 -->
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on">
							<div style="width: 100%; overflow:hidden;">
								<iframe style="position:relative; top:-60px;" src="https://www.google.com/maps/d/embed?mid=1934NlHHpuSkSoHNxggTlqm89n69-K6o&ehbc=2E312F" width="100%" height="510"></iframe>
							</div>							
						</li>

						<!-- SLIDE 6 old -->
						<!--
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on">
							<img alt="" :src="`/img/revolution-slider/slider1700x449_06_${lang}.jpg`" />
							<div data-marker-location="FOGGIA" class="caption sft" data-x="535" data-y="230" data-speed="500" data-start="1500">
								<img src="img/revolution-slider/move/marker-blue-r.png">
							</div>
							<div data-marker-location="SALERNO" class="caption sft" data-x="488" data-y="245" data-speed="500" data-start="1700">
								<img src="img/revolution-slider/move/marker-red-l.png" style="width:40px">
							</div>
							<div data-marker-location="ASHABAT" class="caption sft" data-x="940" data-y="275" data-speed="500" data-start="1900">
								<img src="img/revolution-slider/move/marker-red-wrench-l.png">
							</div>
							<div data-marker-location="DUSHANBE" class="caption sft" data-x="1050" data-y="270" data-speed="500" data-start="2100">
								<img src="img/revolution-slider/move/marker-red-wrench-l.png">
							</div>
							<div data-marker-location="BISHKEK" class="caption sft" data-x="1150" data-y="210" data-speed="500" data-start="2300">
								<img src="img/revolution-slider/move/marker-red-wrench-r.png">
							</div>
							<div data-marker-location="ISLAMABAD" class="caption sft" data-x="1125" data-y="325" data-speed="500" data-start="2500">
								<img src="img/revolution-slider/move/marker-red-wrench-r.png">
							</div>
							<div data-marker-location="CHANTILLY" class="caption sft" data-x="360" data-y="115" data-speed="500" data-start="2700">
								<img src="img/revolution-slider/move/marker-red-wrench-l.png">
							</div>
							<div data-marker-location="MOSCA" class="caption sft" data-x="720" data-y="5" data-speed="500" data-start="2900">
								<img src="img/revolution-slider/move/marker-red-wrench-l.png">
							</div>
							<div data-marker-location="S.PIETROBURGO" class="caption sft" data-x="620" data-y="10" data-speed="500" data-start="3100">
								<img src="img/revolution-slider/move/marker-red-wrench-dl.png">
							</div>
							<div data-marker-location="ISTANBUL" class="caption sft" data-x="630" data-y="235" data-speed="500" data-start="3300">
								<img src="img/revolution-slider/move/marker-red-wrench-l.png">
							</div>
						</li>
						-->

						<!-- SLIDE 7 -->
						<li data-transition="fade" data-slotamount="7" data-masterspeed="500" data-saveperformance="on">
							<img alt="" src="/img/revolution-slider/slider1700x449_07.jpg" />
							<div class="caption sfr rs-parallaxlevel-0"
								:data-x="locale.slide_07_01_x"
								data-y="30"
								data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
								data-speed="1000"
								data-start="1400"
								data-easing="Power3.easeInOut"
								data-splitin="none"
								data-splitout="none"
								data-elementdelay="0.1"
								data-endelementdelay="0.1"
								style="z-index: 7; max-width: auto; max-height: auto; white-space: nowrap; font-family: Calibri; font-size:28px; font-weight:bold; padding-left:50px;color:#fff;text-shadow: 2px 2px rgba(0,0,0,0.75) !important;">
								{{ locale.slide_07_01 }}
							</div>
						</li>
					</ul>
					<div class="tp-bannertimer"></div>
				</div>
			</div>
		</section>
  </div>
</template>

<script>
export default {
  name: 'revolution-slider',
	data:() => ({
		lang: null,
		started: false,
		locales: {
			it: {
				slide_01_01a: 'Prenota adesso',
				slide_01_01b: 'il tuo viaggio',
				slide_01_01c: 'alle isole Tremiti!',
				slide_01_01_x: 185,
				slide_01_02a: '"Il vero viaggio di scoperta non consiste nel cercare nuove terre,',
				slide_01_02b: 'ma nell\'avere nuovi occhi"',

				slide_02_01: 'Vola con &nbsp; Alidaunia',
				slide_02_02: '"Le persone non fanno i viaggi,<br />sono i viaggi che fanno le persone"',
				
				slide_03_01: 'Alidaunia è Elisoccorso e',
				slide_03_01_y: 200,
				slide_03_02_y: 180,
				
				slide_04_01: 'Alidaunia è Scuola di Addestramento al Volo',
				
				slide_05_01: 'Alidaunia è Centro Servizi Autorizzato Leonardo',

				slide_07_01: 'L\'eliporto Alidaunia è sulla S.S.673 km 19,00',
				slide_07_01_x: 500
			},
			en: {
				slide_01_01a: 'Book now',
				slide_01_01b: 'your travel',
				slide_01_01c: 'for Tremiti islands!',
				slide_01_01_x: 105,
				slide_01_02a: '"The real voyage of discovery consists, not in seeking new landscapes,',
				slide_01_02b: 'but in having new eyes"',

				slide_02_01: 'Fly with &nbsp; Alidaunia',
				slide_02_02: '"People don\'t take trips,<br />trips take people"',
				
				slide_03_01: 'Alidaunia is Helicopter<br />Emergency Medical Rescue',
				slide_03_01_y: 150,
				slide_03_02_y: 130,
				
				slide_04_01: 'Alidaunia is Approved Training Organization',
				
				slide_05_01: 'Alidaunia is Authorized Leonardo Service Center',

				slide_07_01: 'Alidaunia Headquarter & Heliport is on highway "S.S.673" km 19,00',
				slide_07_01_x: 400
			}
		}
	}),
	computed: {
		locale() {
			return this.locales[this.lang]
		}
	},
	watch: {
		started(val, oldVal) {
			if (! oldVal && val) {
				// console.log('STARTED')
				let preloader = document.getElementById('preloader-rs')
				let revoSlider = document.getElementById('revo-slider')

				revoSlider.classList.remove('hidden')
				preloader.classList.add('hidden')
			}
		}
	},
  methods: {
		initRevolutionSlider() {
			$(document).ready(() => {
				$('.banner').revolution({
					//delay: 9000,
					startwidth: 1170,
					startheight: 449,
					autoHeight: "off",
					fullScreenAlignForce: "off",

					onHoverStop: "on",

					thumbWidth: 100,
					thumbHeight: 50,
					thumbAmount: 3,

					hideThumbsOnMobile: "on",
					hideBulletsOnMobile: "on",
					hideArrowsOnMobile: "on",
					hideThumbsUnderResoluition: 0,

					hideThumbs: 0,
					hideTimerBar: "off",

					keyboardNavigation: "on",

					navigationType: "none",
					navigationStyle: "navbar-old",
					navigationArrows: "solo",

					navigationHAlign: "left",
					navigationVAlign: "bottom",
					navigationHOffset: 30,
					navigationVOffset: 30,

					soloArrowLeftHalign: "left",
					soloArrowLeftValign: "center",
					soloArrowLeftHOffset: 20,
					soloArrowLeftVOffset: 0,

					soloArrowRightHalign: "right",
					soloArrowRightValign: "center",
					soloArrowRightHOffset: 20,
					soloArrowRightVOffset: 0,

					touchenabled: "on",
					swipe_velocity: "0.7",
					swipe_max_touches: "1",
					swipe_min_touches: "1",
					drag_block_vertical: "false",

					stopAtSlide: -1, //1
					stopAfterLoops: -1, //0
					loopSingleSlide: "off",
					hideCaptionAtLimit: 0,
					hideAllCaptionAtLilmit: 0,
					hideSliderAtLimit: 0,

					dottedOverlay: "none",

					fullWidth: "off",
					forceFullWidth: "off",
					fullScreen: "off",
					fullScreenOffsetContainer: "#topheader-to-offset",

					shadow: 0,
					videoJsPath: "/packages/rs-plugin/video.js/"
				})        
			})
		}
	},
	created() {
		this.lang = this.$parent.lang
	},
  mounted() {
		EventBus.$on('extLibrariesLoaded', () => {
			// console.log('► start revoslider')

			// const iframeMap = document.getElementById('alidaunia-google-maps')
			// const doc = iframeMap.contentDocument
			// doc.body.innerHTML = doc.body.innerHTML + '<style>div.i4ewOd-pzNkMb-haAclf.QUIbkc { display: none !important; }</style>'
			
			this.initRevolutionSlider()
		})

		var firstSlide = document.getElementById('revo-slider').querySelector('ul li:first-child')

		let self = this

		new MutationObserver(mutations => {
			mutations.forEach(mutation => {
				self.started = true
				// console.log('►►►', mutation)
			})
		})
		.observe(firstSlide, {
			attributes: true //configure it to listen to attribute changes
		})
	}
}
</script>
