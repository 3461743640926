<template>
	<div class="pax-block-main flex flex-col w-full border-solid border-blue-800 rounded border shadow-sm mb-4">
		<div class="flex justify-between items-center text-white bg-blue-800 px-2 py-1">
			<div v-if="logged" class="flex">
				<span v-if="category == 'a' && gender == 'F'" class="leading-none" style="font-size: 1.3rem">
					<span class="rounded-full flex justify-center items-start w-4 h-4 text-xs" style="font-size: 0.65rem">
						<fa class="s-asterisk text-pink-600 fa-spin bg-white rounded-full p-1" />
					</span>
				</span>
			</div>
			<div v-if="! logged" class="flex">
				{{ category == 'a' && gender == 'M' ? locale.bookingAdult + '_' + locale.bookingMan : '' }}
				{{ category == 'a' && gender == 'F' ? locale.bookingAdult + '_' + locale.bookingWoman : '' }}
				{{ category == 'c' ? locale.bookingChild : '' }}
				{{ category == 'i' ? locale.bookingInfant : '' }}
				{{ category == 'an' ? locale.animalsInTow : '' }}
				<span v-if="category == 'a' && gender == 'F' && idx >= 0" class="ml-2 leading-none" style="font-size: 1.3rem">
					<fa class="s-asterisk fa-spin relative text-xs text-pink-600 bg-white rounded-full p-1" style="top: -1px" />
					<!--
					<span class="rounded-full flex justify-center items-start w-4 h-4 text-xs" style="font-size:0.65rem">
						<fa class="s-asterisk text-pink-600 fa-spin bg-white rounded-full p-1" />
					</span>
					-->
				</span>
			</div>
			<div v-if="idx >= 0" class="pax-badge" v-text="idx + 1"></div>
		</div>
		<div class="p-2 flex flex-col md:flex-row" :class="{ '': ! logged, 'bg-gray-100': logged }">
			<div class="w-full md:mb-0 mb-2" :class="{ 'md:w-4/5': idx >= 0 }">
				<div v-if="category != 'an'" class="flex flex-wrap mb-4 md:mb-0">
					<div class="lastname w-full md:w-1/2 mb-2 md:mb-0" data-cy="pax-lastname-container">
						<label class="label">
							{{ locale.bookingLastname }}
						</label>
						<vue-select
							ref="select"
							v-if="! id"
							v-model="paxTemp"
							:custom="true"
							@search-change="v => updatePaxFromSelect(v)"
							@select="v => confirmSelection(v)"
							@tag="v => confirmSelection(v)"
							@close="closeSelect"
						/>
						<div v-else class="flex flex-wrap items-stretch w-full relative">
							<span v-text="form.lastname" type="text" class="appearance-none block w-full text-gray-700 border-b border-gray-400 select-none px-2 py-1 flex-1" />
							<div v-if="! logged" class="flex -mr-px items-end border-b border-gray-400">
								<span @click="form.id = 0" class="bg-red-500 rounded cursor-pointer flex justify-center items-center leading-normal whitespace-nowrap text-white w-4 h-4 relative" style="top:-6px">
									<fa class="s-times text-xs" />
								</span>
							</div>
						</div>
					</div>
					<div class="firstname w-full md:w-1/2 mb-2 md:mb-0 md:pl-2">
						<label class="label" for="grid-last-name">
							{{ locale.bookingFirstname }}
						</label>
						<input v-if="id <= 0" v-model="form.firstname" class="field" type="text" data-cy="pax-firstname">
						<span v-if="id > 0" v-text="form.firstname" type="text" class="appearance-none block w-full text-gray-700 border-b border-gray-400 select-none px-2 py-1 flex-1" />
					</div>
					<div class="w-full flex flex-wrap justify-between md:flex-no-wrap md:mt-2">
						<div class="w-full flex">
							<div class="gender w-1/2 mb-2 md:mb-0 md:w-auto md:mr-6">
								<label class="label">
									{{ locale.bookingGender }}
								</label>
								<div v-if="id <= 0 && (category != 'a' || idx < 0)" class="inline-block relative w-16">
									<select v-model="form.gender" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-2 py-1 pr-8 rounded shadow" data-cy="pax-gender">
										<option value="M">M</option>
										<option value="F">F</option>
									</select>
									<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
										<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
									</div>
								</div>
								<span v-else v-text="form.gender" type="text" class="appearance-none block w-8 text-center text-gray-700 border-b border-gray-400 select-none px-2 py-1 flex-1" />
							</div>
							<div class="birthdate w-1/2 mb-2 md:mb-0 md:w-40">
								<label class="label">
									{{ locale.bookingBirthDate }}
								</label>
								<vue-datepicker v-model="form.birthDate" />
							</div>
						</div>
					</div>
					<div class="mt-2 w-full flex space-x-2">
						<div class="w-2/3">
							<label class="label">
								Documento
							</label>
							<div class="flex">
								<div class="inline-block relative">
									<select v-model="form.document_type" class="block appearance-none bg-white border border-gray-400 hover:border-gray-500 px-2 py-1 pr-8 rounded-l border-r-0 shadow" data-cy="pax-doc-type">
										<option :value="0" class="italic">&nbsp;</option>
										<option :value="1">Carta d'identità</option>
										<option :value="2">Passaporto</option>
									</select>
									<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
										<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
									</div>
								</div>
								<input v-model="form.document_num" data-cy="pax-doc-num" class="field !rounded-l-none" type="text">
							</div>
						</div>
						<div class="w-1/3">
							<label class="label">
								<span>Scad.docum.</span>
							</label>
							<vue-datepicker v-model="form.document_expdate" />
						</div>
					</div>
					<div class="w-full flex flex-col md:mt-2" :class="{ 'md:flex-row': idx >= 0 }">
						<div class="w-full mb-2" :class="{ 'md:w-1/3': idx >= 0 }">
							<label class="label">
								{{ locale.bookingResidence }}
							</label>
							<input v-model="form.residence" class="field" type="text">
						</div>
						<div class="flex flex-col md:flex-row" :class="{ 'md:w-2/3': idx >= 0 }">
							<div class="w-full md:w-1/2 mb-2" :class="{ 'md:pl-2': idx >= 0, 'md:pl-0': idx == 0 }">
								<label class="label">
									Email
								</label>
								<div class="flex flex-wrap items-stretch w-full relative">
									<input v-model="form.email" data-cy="pax-email" type="text" class="appearance-none block w-full text-gray-700 border border-gray-500 rounded rounded-r-none px-2 py-1 flex-1">
									<div v-if="idx >= 0" class="flex -mr-px border border-gray-500 rounded rounded-l-none">
										<button type="button" @click="setContactsEmail(form.email)" data-cy="pax-email-selector" class="bg-blue-400 cursor-pointer flex items-center leading-normal rounded rounded-l-none border border-l-0 border-gray-400 px-3 whitespace-nowrap text-white text-sm" content="usa come email di contatto" v-tippy="{ arrow: true }">
											<fa class="r-copy" />
										</button>
									</div>
								</div>
							</div>
							<div class="w-full md:w-1/2 mb-2 md:pl-2">
								<label class="label">
									{{ locale.bookingTelephone }}
								</label>
								<div class="flex flex-wrap items-stretch w-full relative">
									<input v-model="form.telephone" data-cy="pax-phone" type="text" class="appearance-none block w-full text-gray-700 border border-gray-500 rounded rounded-r-none px-2 py-1 flex-1">
									<div v-if="idx >= 0" class="flex -mr-px border border-gray-500 rounded rounded-l-none">
										<button type="button" @click="setContactsTelephone(form.telephone)" data-cy="pax-phone-selector" class="pax-contact-phone-btn bg-blue-400 cursor-pointer flex items-center leading-normal rounded rounded-l-none border border-l-0 border-gray-400 px-3 whitespace-nowrap text-white text-sm" content="usa come telefono di contatto" v-tippy="{ arrow: true }">
											<fa class="r-copy" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="category == 'an'" class="flex flex-wrap mb-4 md:mb-0">
					<div class="birthdate w-1/2 mb-2 md:mb-0 md:w-40">
						<label class="label">
							Associato al pax n.
						</label>
						<div class="inline-block relative w-16">
							<select v-model="form.animal_owner_key" data-cy="pax-doc-type" class="field-rounded-l field-rounded-r bg-white">
								<option v-if="index != $parent.form.paxes.length - 1" v-for="(pax, index) in $parent.form.paxes" :value="index" v-text="index + 1" />
							</select>
							<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
								<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="idx >= 0" class="w-full md:w-1/5 md:ml-2">
				<table class="text-left w-full border border-gray-500 text-sm" :class="{ 'bg-white': ! logged, 'bg-gray-200': logged }">
					<tbody>
						<tr v-if="checkRoles()">
							<td colspan="2" style="background-color:lightcyan">
								<div class="flex">
									<div class="w-1/3 flex justify-center items-center" style="font-size:.6rem">
										Agevolazione {{ pax.category == 'an' ? 'proprietario' : '' }}
									</div>
									<div class="w-2/3 text-right">
										<div class="inline-block relative w-32">
											<select v-model="form.exemption" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-1 pr-8 rounded" :style="exemptionColor">
												<option :value="0" style="background-color:white">-</option>
												<option :value="1" style="background-color:greenyellow">Residente</option>
												<option :value="2" style="background-color:yellow">Lav.Pend.</option>
												<option :value="3" style="background-color:yellow">Stud.Pend.</option>
												<!-- <option class="hide" :value="4" style="background-color:yellow">Minore 10 anni</option> -->
												<option :value="5" style="background-color:white">Divers.abile</option>
												<option :value="6" style="background-color:yellow">Parificato res.</option>
											</select>
											<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
												<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
						<tr v-if="checkRoles() || user.userId == -44 || user.userId == -185">
							<td colspan="2" style="background-color:lightcyan" :style="dscColor">
								<div class="flex">
									<div class="w-1/3 flex justify-center items-center" style="font-size:.6rem">
										Sconto
									</div>
									<div class="w-2/3 text-right">
										<div class="inline-block relative w-32">
											<select v-if="user.userId != 44 && user.userId != 185" v-model="form.dsc" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-1 pr-8 rounded">
												<option :value="0">-</option>
												<option :value="10">10%</option>
												<option :value="20">20%</option>
												<option :value="30">30%</option>
												<option :value="40">40%</option>
												<option :value="50">50%</option>
											</select>
											<select v-if="user.userId == -44" v-model="form.dsc" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-1 pr-8 rounded shadow">
												<option :value="0">-</option>
												<option :value="40">40%</option>
											</select>
											<select v-if="user.userId == -185" v-model="form.dsc" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-1 pr-8 rounded shadow">
												<option :value="0">-</option>
												<option :value="30">30%</option>
											</select>
											<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
												<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2" style="background-color:rgba(17,77,136,0.1);"></td>
						</tr>
						<tr>
							<th class="text-right pr-2 border-b" style="font-size:.6rem">{{ locale.bookingFare }}</th>
							<td class="text-right pr-2 border-b border-gray-300 cell-fare-name">
								<span>{{ form.fare }}</span>
							</td>
						</tr>
						<tr v-if="category != 'an'">
							<th class="text-right pr-2 border-b" style="font-size:.6rem">{{ locale.bookingPaxCategory }}</th>
							<td class="text-right pr-2 border-b border-gray-300">
								<span v-if="category == 'a'">AD</span>
								<span v-else-if="category == 'c'">CH</span>
								<span v-else-if="category == 'i'">INF</span>
								<span v-else-if="category == 'an'">-</span>
							</td>
						</tr>
						<tr>
							<th class="text-right pr-2 border-b" style="font-size:.6rem">{{ locale.bookingTicket }}</th>
							<td class="text-right pr-2 border-b border-gray-400 cell-fare-price" style="color:darkgreen;background-color:rgba(162, 216, 97, 0.38)">
								€ <span v-text="formattedPrice(form.ticket)"></span>
							</td>
						</tr>
						<tr v-if="category != 'an'">
							<th class="text-right pr-2 border-b" style="font-size:.6rem">{{ locale.bookingTaxes }}</th>
							<td class="text-right pr-2 border-b border-gray-400 cell-additional-fee" style="color:darkgreen;background-color:rgba(162, 216, 97, 0.38)">
								€ <span v-text="formattedPrice(form.tax)"></span>
							</td>
						</tr>
						<tr v-if="category != 'an'">
							<th class="text-right pr-2 border-b" style="font-size:.6rem" v-text="locale.bookingLandingTax.replace('<br>', ' ')"></th>
							<td class="text-right pr-2 border-b border-gray-400 cell-landing-fee" style="color:darkgreen;background-color:rgba(162, 216, 97, 0.38)">
								€ <span v-text="formattedPrice(form.landingTax)"></span>
							</td>
						</tr>
						<tr>
							<th class="text-right pr-2" style="font-size:.6rem">{{ locale.bookingTotal }}</th>
							<td class="text-right pr-2 cell-passenger-total" style="color:#fff;background-color:darkgreen">
								€ <span v-text="formattedPrice(form.total)"></span>
							</td>
						</tr>
					</tbody>
				</table>
				<!--
				soglia ad: {{ thresholds.adult.format('DD/MM/YYYY') }}
				<br>
				soglia ch: {{ thresholds.child.format('DD/MM/YYYY') }}
				<br>
				<button type="button" @click="checkAge">check</button>
				-->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		idx: {
			type: Number
		},
		pax: {
			type: Object
		}
	},
	inject: ['hasAnyRole_function'],
	data:() => ({
		paxTemp: null,
		form: {
			_uuid: '',
			id: 0,
			category: '',
			tktCategory: '',
			gender: '',
			firstname: '',
			lastname: '',
			birthDate: '',
			document_type: 0,
			document_num: null,
			document_expdate: null,
			residence: '',
			email: '',
			telephone: '',
			ticket: 0,
			tax: 0,
			landingTax: 0,
			logged: false,
			fare: '',
			exe: 0,
			dsc: 0,
			total: 0
		},
		searchPromise: null,
		query: null
	}),
	computed: {
		locale() {
			return this.$parent.locale
		},
		id() {
			return this.form.id
		},
		flightType() {
			return this.$parent.form.flightType
		},
		category() {
			return this.form.category
		},
		gender() {
			return this.form.gender
		},
		departureDate() {
			return moment(this.$parent.form.departureDate, 'DD/MM/YYYY')
		},
		birthDate() {
			if (this.form.birthDate) {
				return moment(this.form.birthDate, 'DD/MM/YYYY')
			}
			else {
				return null
			}
		},
		thresholds() {
			return this.$parent.thresholds
		},
		user() {
			return this.$parent.user
		},
		prices() {
			return this.$parent.prices
		},
		logged() {
			return this.form.logged
		},
		exemptionColor() {
			if ([1].includes(this.form.exemption)) {
				return { 'background-color': 'greenyellow' }
			}
			else if ([2, 3, 4, 6].includes(this.form.exemption)) {
				return { 'background-color': 'yellow' }
			}
		},
		dscColor() {
			if (this.form.dsc > 0) {
				return { 'background-color': 'yellow' }
			}
		}
	},
	watch: {
		form: {
			handler(val) {
				this.emitUpdate()
			},
			deep: true
		},
		'form.id': {
			handler(val, oldVal) {
				var category = this.category

				if (! val && oldVal) {
					this.form.lastname = ''
					this.form.firstname = ''
					this.form.birthDate = null
					this.form.residence = ''
					this.form.document_type = 0
					this.form.document_num = null
					this.form.document_expdate = null
					this.form.email = ''
					this.form.telephone = ''
					this.form.exemption = 0
					this.form.dsc = 0
					if (category != 'a') {
						this.form.gender = ''
					}
				}

				this.calculateTotal()
			},
			deep: true
		},
		'form.birthDate': {
			handler(val, oldVal) {
				this.calculateTotal()
			}
		},
		'form.exemption': {
			handler(val, oldVal) {
				// console.info('CHANGED EXEMP for ' + this.form._uuid + ' from ' + oldVal + ' to ' + val)
				this.calculateTotal()
			}
		},
		'form.dsc': {
			handler(val, oldVal) {
				// console.info('CHANGED DSC for ' + this.form._uuid + ' from ' + oldVal + ' to ' + val)
				this.calculateTotal()
			}
		}
	},
	methods: {
		hasAnyRole(roles) {
			const status = this.hasAnyRole_function(roles)
      return status
    },
		checkRoles() {
			return this.hasAnyRole('Prenotazioni|PrenotaFull')
		},
		closeSelect() {
			if (this.form.lastname && this.form.id == 0) {
				this.form.id = -1
			}
		},
		startCountingTimeout(seconds = 3) {
			// State for managing cleanup and cancelling
			let finished = false;
			let cancel = () => finished = true;

			const promise = new Promise((resolve, reject) => {
				let counts = 0;
				const id = setInterval(() => {
					counts += 1
					console.log(counts);
					if (counts === seconds) {
						// Promise solved scenario
						this.searchPax()
						clearInterval(id)
						resolve()
					}
				}, 1000)

				// When consumer calls `cancel`:
				cancel = () => {
					// In case the promise has already resolved/rejected, don't run cancel behavior!
					if (finished) {
						return;
					}

					// Cancel-path scenario
					clearInterval(id);
					reject();
				};

				// If was cancelled before promise was launched, trigger cancel logic
				if (finished) {
					// (to avoid duplication, just calling `cancel`)
					cancel();
				}

			})
			// After any scenario, set `finished = true` so cancelling has no effect
			.then((resolvedValue) => {
				finished = true;
				return resolvedValue;
			})
			.catch((err) => {
				finished = true;
				return err;
			});

			return { promise, cancel }
		},
		async updatePaxFromSelect(query) {
			var strLengthMin = 3
			if (! this.checkRoles()) {
				strLengthMin = 0
			}

			if (query.length < strLengthMin) { return }

			if (this.searchPromise) {
				this.searchPromise.cancel()
			}
			this.query = query
			this.searchPromise = this.startCountingTimeout(1)
		},
		async searchPax() {
			console.log('SEARCH PAX')
			// return
			try {
				let url = route('api.paxes.index')

				let { query } = this

				let payload = {
					lastName: query,
					flightDate: this.departureDate.format('DD/MM/YYYY'),
					paxCategory: this.form.category,
					paxGender: this.form.gender
				}

				let { data } = await api.get(url, payload)
				this.$refs['select'].options = data.map(i => {
					return {
						id: i.val,
						txt: i.txt,
						extra: i.extra
					}
				})
				this.$refs['select'].isLoading = false
			}
			catch (error) {
				callErrors(error)
				if (error.response.status == 401) {
					this.$parent.goToStep(401)
				}
				this.$refs['select'].isLoading = false
			}
		},
		confirmSelection(val) {
			var isObj = val instanceof Object

			if (isObj) {
				this.form.id = val.id
				this.form.lastname = val.extra.lastname
				this.form.firstname = val.extra.firstname
				this.form.gender = val.extra.gender
				this.form.birthDate = val.extra.birthDate
				this.form.residence = val.extra.residence
				this.form.document_type = val.extra.document_type
				this.form.document_num = val.extra.document_num
				this.form.document_expdate = val.extra.document_expdate
				this.form.email = val.extra.email
				this.form.telephone = val.extra.telephone
				this.form.exemption = val.extra.exemption
			}
			else {
				// ---> this.form.id = -1
				this.form.lastname = val
			}
		},
		emitUpdate() {
			this.$emit('update', this.form)
		},
		formattedPrice(val) {
			return parseFloat(val).toFixed(2)
		},
		calculateTotal() {
			var category = this.category

			var flightType = this.flightType

			/* TARIFFA RESIDENTE SE: */
			// - 1. residente
			// - 2. lav.pendolare
			// - 3. stud.pendolare
			// - 6. parificato res.

			var isResident = false
			if ([1, 2, 3, 6].includes(this.form.exemption)) {
				isResident = true
			}

			/********************/
			/* BLOCCO DI ANDATA */
			/********************/

			var departureFares = this.$parent.fares.departure
			var departureTaxes = this.$parent.taxes.departure
			var departureLandingTax = this.$parent.landingTaxes.departure

			if (isResident) {
				var departureFare = departureFares.filter(f => f.code == 'RES')[0]
			}
			else {
				var departureFare = departureFares.filter(f => f.code != 'RES')[0]
			}

			this.form.fare = departureFare.code

			if (category == 'a') {
				this.form.ticket = departureFare.Ad
				this.form.tax = departureTaxes.Ad
				this.form.landingTax = departureLandingTax
			}
			else if (category == 'c') {
				this.form.ticket = departureFare.Ch
				this.form.tax = departureTaxes.Ch
				this.form.landingTax = departureLandingTax
			}
			else if (category == 'i') {
				this.form.ticket = departureFare.Inf
				this.form.tax = departureTaxes.Inf
				this.form.landingTax = 0
			}
			else if (category == 'an') {
				this.form.ticket = departureFare.Ch
			}

			/*********************/
			/* BLOCCO DI RITORNO */
			/*********************/

			if (flightType == 'DW') {
				var comebackFares = this.$parent.fares.comeback
				var comebackTaxes = this.$parent.taxes.comeback
				var comebackLandingTax = this.$parent.landingTaxes.comeback

				if (isResident) {
					var comebackFare = comebackFares.filter(f => f.code == 'RES')[0]
				}
				else {
					var comebackFare = comebackFares.filter(f => f.code != 'RES')[0]
				}

				if (category == 'a') {
					this.form.ticket = parseFloat(this.form.ticket) + parseFloat(comebackFare.Ad)
					this.form.tax = parseFloat(this.form.tax) + parseFloat(comebackTaxes.Ad)
					this.form.landingTax = parseFloat(this.form.landingTax) + parseFloat(comebackLandingTax)
				}
				else if (category == 'c') {
					this.form.ticket = parseFloat(this.form.ticket) + parseFloat(comebackFare.Ch)
					this.form.tax = parseFloat(this.form.tax) + parseFloat(comebackTaxes.Ch)
					this.form.landingTax = parseFloat(this.form.landingTax) + parseFloat(comebackLandingTax)
				}
				else if (category == 'i') {
					this.form.ticket = parseFloat(this.form.ticket) + parseFloat(comebackFare.Inf)
					this.form.tax = parseFloat(this.form.tax) + parseFloat(comebackTaxes.Inf)
					this.form.landingTax = 0
				}
				else if (category == 'an') {
					this.form.ticket = parseFloat(this.form.ticket) + parseFloat(comebackFare.Ch)
				}

				if (departureFare.code != comebackFare.code) {
					this.form.fare = departureFare.code + ' + ' + comebackFare.code
				}
			}

			this.form.ticket = parseFloat(this.form.ticket)
			this.form.ticket_nodsc = parseFloat(this.form.ticket)
			if (this.form.dsc != 0) {
				var ticket = this.form.ticket
				var dsc = this.form.dsc
				this.form.ticket = parseFloat(ticket - (ticket / 100 * dsc))
				this.form.ticket_nodsc = ticket
			}
			this.form.tax = parseFloat(this.form.tax) //.toFixed(2)
			this.form.landingTax = parseFloat(this.form.landingTax) //.toFixed(2)

			/*********************************/
			/* CALCOLO ESENZIONI LANDING-TAX */
			/*********************************/

			var birthDate = this.birthDate
			var departureDate = this.departureDate

			// - residente
			if (isResident) {
				this.form.landingTax = 0
			}
			// - minore di 10 anni rispetto alla data volo
			if (category == 'c' && birthDate) {
				console.info('***************')
				console.info(birthDate.format('DD-MM-YYYY'))
				console.info(departureDate.clone().subtract(10, 'years').format('DD-MM-YYYY'))
				console.info(birthDate > departureDate.clone().subtract(10, 'years'))
				console.info('***************')
			}
			if (category == 'c' && birthDate && birthDate > departureDate.clone().subtract(10, 'years')) {
				this.form.landingTax = 0
			}
			// - altre esenzioni previste
			if (this.form.exemption != 0) {
				this.form.landingTax = 0
			}

			this.form.total = parseFloat(this.form.ticket + this.form.tax + this.form.landingTax)
			this.form.total_nodsc = parseFloat(this.form.ticket_nodsc + this.form.tax + this.form.landingTax)

			this.emitUpdate()
		},
		checkAge() {
			var paxCategory = this.form.category
			var paxBirthDate = moment(this.form.birthDate, 'DD/MM/YYYY')

			var threshold_Ad = this.thresholds.adult
			var threshold_Ch = this.thresholds.child
			var threshold_Departure = this.departureDate

			var valid = false

			if (paxCategory == 'a') {
				console.info('check Ad')
				valid = paxBirthDate <= threshold_Ad
			}
			if (paxCategory == 'c') {
				console.info('check Ch')
				valid = paxBirthDate > threshold_Ad && paxBirthDate <= threshold_Ch
			}
			if (paxCategory == 'i') {
				console.info('check Inf')
				valid = paxBirthDate > threshold_Ch && paxBirthDate <= threshold_Departure
			}

			console.info(paxBirthDate)
			console.info(threshold_Ad)
			console.info(threshold_Ch)
			console.info(threshold_Departure)
			console.info(valid)

			return valid
		},
		setContactsEmail(email) {
			this.$parent.form.contacts.email = email
		},
		setContactsTelephone(telephone) {
			this.$parent.form.contacts.telephone = telephone
		}
	},
	created() {
		this.form = this.pax
		if (this.pax.category == 'an' && ! this.pax.hasOwnProperty('animal_owner_key')) {
			this.$set(this.form, 'animal_owner_key', 0)
		}

		if (this.form.logged) {
			this.form.id = this.user.pax.id
			this.form.lastname = this.user.pax.lastname
			this.form.firstname = this.user.pax.firstname
			this.form.birthDate = this.user.pax.birthdate
			this.form.residence = this.user.pax.address
			this.form.document_type = this.user.pax.document_type
			this.form.document_num = this.user.pax.document_num
			this.form.document_expdate = this.user.pax.document_expdate
			this.form.fare = ''
			this.form.exemption = this.user.pax.exemption
			this.form.dsc = 0
			this.form.total = 0
		}

		this.calculateTotal()

		emitter.on('preset-pax-block', ({ paxCategory, gender }) => {
			Object.assign(this.form, {
        birthDate: null,
        category: paxCategory,
        document_num: null,
				document_expdate: null,
        document_type: 0,
        dsc: 0,
        email: null,
        exemption: 0,
        firstname: '',
        gender,
        id: 0,
        lastname: null,
        logged: false,
        residence: null,
        tax: 0,
        telephone: null,
        ticket: 0,
        ticket_nodsc: 0,
        tktCategory: '',
        total: 0,
        total_nodsc: 0
      })
		})
	}
}
</script>
