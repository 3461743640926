<template>
	<section class="w-full max-w-5xl mx-auto bg-white min-h-screen">
		<div class="relative w-full flex flex-col">
      <div id="logout" class="h-screen">
			  <vue-loading :loading="loading" />
      </div>
		</div>
	</section>
</template>

<script>
export default {
  name: 'logout',
  data:() => ({
    loading: true
  }),
  methods: {
    async cleanData() {
      try {
        this.$parent.auth.user = {
          id: null,
          email: null,
          verified: null,
          pax: null,
          roles: []
        }

        localStorage.removeItem('user')

        await api.post('api.auth.logout')

        location.href = '/'
      }
      catch(error) {
        callErrors(error)
      }
    }
  },
  created() {
    this.cleanData()
  }
}
</script>
