import Vue from 'vue'
window.Vue = new Vue

const objDatesConverter = (dates, obj) => dates.forEach(k => {
	if (! obj[k]) { obj[k] = null }
	else { obj[k] = moment(obj[k], 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss') }
})
const objValTxtConverter = (replacer, obj) => Object.keys(replacer).reduce((a, b) => ({ ...a, [replacer[b] || b + '_id']: obj[b] ? obj[b].val : null }), {})
const objShortener = (replacer, obj) => Object.keys(replacer).forEach(k => delete obj[k]);
window.payloader = (obj, dates, objs2ids) => {
		if (dates) {
			objDatesConverter(dates, obj)
		}
		if (objs2ids) {
			obj = { ...obj, ...objValTxtConverter(objs2ids, obj) }
			objShortener(objs2ids, obj)
		}
		return obj
}

String.prototype.replaceAll = function(search, replacement) {
	var target = this
	return target.replace(new RegExp(search, 'g'), replacement)
}

Number.prototype.replaceAll = function(search, replacement) {
	var target = this
	console.info('NUMBER', target)
	return target.toString().replace(new RegExp(search, 'g'), replacement) //.toFixed(2)
}

window.snackbar = function (text, color = 'green') {
	vm.snackbar = true
	vm.snackbarText = text
	vm.snackbarColor = color
}
window.snackbarOff = function () {
	vm.snackbar = false
}

/******************** */
class UserClass {
    constructor(user) {
        this.user = user
    }

    set(user) {
        this.user = user
    }

    toString() {
        return this.user
    }
}
Vue.prototype.$user = new UserClass()
/******************** */

window.$loadUser = function () {
	let userLocalStorage = localStorage.getItem('user')
	if (userLocalStorage && userLocalStorage != 'undefined') {
		let user = JSON.parse(userLocalStorage)
		dbg(user, '-=- localStorage-User -=-')
		user.logged = true
		user.permissions = [
			{ subject: 'all', actions: 'manage' },
			{ inverted: true, subject: 'Message', actions: 'delete' }
		]
		dbg(user, '-= USER ALREADY SET =-')
		Vue.prototype.$user.set(user)
		// ability.update(user.permissions)
	}
	else {
		dbg(null, '-= USER NOT SET =-')
		$userReset()
		
	}
}
window.$userReset = function () {
	localStorage.removeItem('token')
	localStorage.removeItem('user')
	
	Vue.prototype.$user.set({ logged: false })

	// ability.update([])
}

/**************/
/*** CLONE  ***/
/**************/


window.clone = (elem) => {
  // if null
  if (elem === null) {
	return null
  }

  // if array
  if (Array.isArray(elem)) {
	// console.info('IS ARRAY', JSON.stringify(elem))
	return JSON.parse(JSON.stringify(elem))
  }

  // if object
  if (typeof elem === 'object') {
	// console.info('IS OBJECT', JSON.stringify(elem))
	var obj = {}
	for (var prop in elem) {
		obj[prop] = clone(elem[prop])
	}
	return obj
  }

  // if anything else
  return elem
}


/**************/
/*** DEBUG  ***/
/**************/

window.dbg = function(entity, label = '~ DBG ~', bgColor = null, fontColor = null) {
	if (entity === undefined) {
		var dbg = entity
	}
	else {
		var dbg = JSON.parse(JSON.stringify(entity))
	}
	// console.info('%c ' + label + ' ', 'background: #333; border-radius: 20px; color: ' + color + '; font-size: 14px; font-weight: bold', dbg)
	
	var typeOf = typeof dbg
	if (dbg == null) {
		typeOf = null
	}
	var leftBgColor = bgColor ? bgColor : 'lightblue'
	var leftFontColor = fontColor ? fontColor : '#000'
	console.log(
		`%c ${label} %c ${typeOf} %c`,
		`background:${leftBgColor}; padding: 1px; border-radius: 3px 0 0 3px;  color: ${leftFontColor}`,
		`background:#1456a2; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff`,
		'background:transparent',
		dbg
	)
}


/******************/
/*** STRINGIFY  ***/
/******************/

window.sgf = function(el) {
	console.info(el)
}


/*******************/
/*** DATE CHECK  ***/
/*******************/

window.formatDates = function(obj) {
	for (const prop in obj) {
		var dateToCheck = obj[prop]
		var isDate = moment(dateToCheck, 'YYYY-MM-DD HH:mm:ss', true).isValid()
		if (isDate) {
			obj[prop] = moment(obj[prop]).format('DD/MM/YYYY')
		}
	}
	return obj
}

window.notNull = function(e, val) {
	// console.info('%c START ', lcg)
	// console.info(e)
	// console.info(val)
	// console.info('%c STOP ', lcr)
	return (val !== null && val !== undefined) ? true : false
}

window.getSegment = function(pos) {
	var segments = window.location.pathname.split( '/' )
	return segments[pos]
}

window.getObjProp = function (obj, prop) {
	return prop.split('.').reduce((a, b) => { return a[b] }, obj)
}

let uuidGen = function () {
	function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1); 
	}
	return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase()
}

window.uniqidGen = uuidGen
window.uuidGen = uuidGen

window.randNum = () => {
	return Math.floor(Math.random() * (999 - 100)) + 100
}

let _uuid = function () {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);Math.floor(Math.random() * (999 - 100)) + 100
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
window._uuid = _uuid

let updateTab = function (elem, uniqid, obj) {
	var tab = elem.$root.tabs.filter(t => t.uniqid == uniqid)[0]
	tab = { tab, ...obj }

	// elem.$parent.tab.id = tender.id
	// elem.$parent.tab.name = tender.name	
}
window.updateTab = updateTab

window.callErrors = (error) => {
	console.error('error name: ' + error.name)
	console.error('error message: ' + error.message)
	if (error.hasOwnProperty('response')) {
		console.error('error status: ' + error.response.status)
	}
	// console.error('error data: ' + JSON.stringify(error.response.data))
	// console.error(error)
}

window.generateUUID = () => {
	var d = new Date().getTime()
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = (d + Math.random()*16)%16 | 0
		d = Math.floor(d/16)
		return (c=='x' ? r : (r&0x3|0x8)).toString(16)
	})
	return uuid
}

window.paginationHandler = (self, resetToFirstPage) => {
	if (resetToFirstPage === undefined) {
		resetToFirstPage = false
	}

	let { pagination } = self

	let current_page = 0
	let per_page = 15

	if (pagination) {
		current_page = pagination.current_page
		if (resetToFirstPage) {
			current_page = 1
		}
		
		per_page = pagination.per_page
	}
	
	return {
		current_page,
		per_page
	}
}
