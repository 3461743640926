<template>
	<label class="inline-block">
		<input v-model="internalValue" :value="value" type="radio" class="form-radio" :style="disabledStyle" style="width:1.2em; height:1.2em" :disabled="disabled">
		<span v-if="label" class="ml-1" v-text="label" />
	</label>
</template>

<script>
export default {
	name: 'VueRadio',
	model: {
		prop: 'model',
		event: 'change'
	},
	props: {
		model: {
			type: String | Boolean,
			default: undefined
		},
		disabled: {
			type: Boolean,
			default: false
		},
		skin: {
			type: String,
			default: 'square'
		},
		color: {
			type: String,
			default: 'blue'
		},
		label: {
			type: String,
			default: null
		},
		value: {
			default: null
		},
		isInteger: {
			type: Boolean,
			default: false
		}
	},
	data:() => ({
		internalValue: null
	}),
	computed: {
		disabledStyle() {
			if (! this.disabled) {
				return ''
			}
			else {
				return {
					'background-color': '#ebebeb !important',
					'cursor': 'default !important'
				}
			}
		}
	},
	watch: {
		model(val) {
			if (val != this.value) {
				this.internalValue = null
			}
			else if (val === this.value) {
				this.internalValue = val
			}
		},
		internalValue(val) {
			if (val != null) {
				this.$emit('change', val)
			}
		},
		value(val) {
			if (val != this.internalValue) {
				this.internalValue = null
			}
		}
	},
	created() {
		if (this.model == this.value) {
			this.internalValue = this.model
		}
	}
}
</script>