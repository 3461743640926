<template>
	<div style="position:relative">
		<vue-multiselect
			:value="value"
			track-by="id"
			label="txt"
			:loading="isLoading"
			:multiple="multiple"
			:taggable="custom"
			:options="options"
			:allow-empty="true"
			:show-labels="false"
			placeholder=""
			tag-placeholder=""
			select-label=""
			selected-label=""
			deselect-label=""
			@select="select"
			@search-change="searchChange"
			@tag="tag"
			@remove="remove"
			@open="loadPaxList"
			@close="close"
		>
			<!-- isOpen = false -->
			<span slot="noResult">
				No results found.
			</span>
			<template v-if="! isOpen" slot="singleLabel" slot-scope="{ option }">
				---
			</template>
			<template slot="option" slot-scope="{ option }">
				<span v-if="option.txt" v-text="option.txt"></span>
				<span v-if="option.extra && option.extra.exemption > 0" class="float-right">
					<fa class="s-asterisk text-orange-500" />
				</span>
				<span v-if="option.isTag" v-text="option.label"></span>
				<span v-if="option.isTag" class="float-right" style="color: white !important; background:green; border:1px solid white !important; border-radius:5px; padding:1px 5px; font-size:10px; position: relative; top: -1px; right: 0px">
					nuovo
				</span>
			</template>
		</vue-multiselect>
		<i v-if="filled && ! isOpen" class="fa fa-times text-red-500" style="position:relative; top:-22px; right:5px; float:right; z-index:2; cursor:pointer" @click.prevent="remove"></i>
	</div>
</template>

<script>
export default {
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: [Object, Array],
			default() {
				return null
			}
		},
		dbgName: {
			type: String
		},
		opts: {
			type: Array,
			default: null
		},
		multiple: {
			type: Boolean,
			default: false
		},
		custom: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			searchKey: '',
			isOpen: false,
			isLoading: false,
			options: []
		}
	},
	computed: {
		compuid() {
			return this._uid
		},
		filled() {
			return this.value !== null && (typeof this.value === 'object' && Object.keys(this.value).length !== 0) || (Array.isArray(this.value) && this.value.length !== 0)
		},
		isSingle() {
			return !this.multiple
		},
		isMultiple() {
			return this.multiple
		},
		isCustom() {
			return this.custom
		},
		isRemote() {
			return this.url != null
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(newVal, oldVal) {
				this.check_if_value_contains_only_id()
			}
		},
		opts: {
			immediate: true,
			handler(newVal, oldVal) {
				if (newVal) {
					this._data.options = newVal
					this.check_if_value_contains_only_id()
				}
			}
		},
	},
	methods: {
		loadPaxList() {
			if (this.$parent.hasOwnProperty('checkRoles') && ! this.$parent.checkRoles()) {
				this.searchChange('')
			}

			this.isOpen = true
		},
		close() {
			this.$emit('close')
		},
		check_if_value_contains_only_id() {
			var value = this.value
			if (value && value.hasOwnProperty('id') && ! value.hasOwnProperty('txt') && this._data.options.length > 0) {
				var id = value.id
				// console.info('%c COMPONENT UID ' + this._uid + ' ', lcy)
				var selectedOption = this._data.options.filter(o => o.id === id)[0]
				if (selectedOption == undefined) {
					this.$emit('change', null)
				}
				else {
					// if 'extra' is available, it's added to value
					var { txt, extra } = selectedOption
					this.$emit('change', { id, txt, extra })
				}
			}
		},
		remove(obj) {
			var value
			if (this.isSingle) {
				value = null
			}
			else if (this.isMultiple) {
				value = this.value.filter(v => v.id != obj.id)
			}
			// if (Array.isArray(value) && value.length === 0) {
			//     value = null
			// }
			this.$emit('change', value)
		},
		select(obj) {
			this.$emit('select', obj)
		},
		tag(txt) {
			this.$emit('tag', txt)
		},
		select2(obj) {
			if (this.isSingle) {
				this.$emit('change', obj)
			}
			else if (this.isMultiple) {
				var value = []
				if (this.value) { value = this.value }
				value.push(obj)
				this.$emit('change', value)
			}
		},
		tag2(txt) {
			if (this.isSingle) {
				var tag = {
					id: 0,
					txt,
					extra: {
						lastname: txt
					}
				}
				this.isCustom ? this.$emit('change', tag) : null
			}
			else if (this.isMultiple) {
				var value = []
				var id = -1
				if (this.value) {
					value = JSON.parse(JSON.stringify(this.value))
					id = Math.max.apply(Math, value.filter(o => o.id < 0).map(o => { return o.id } )) - 1
				}
				var tag = {
					id,
					txt
				}
				value.push(tag)
				this.isCustom ? this.$emit('change', value) : null
			}
		},
		searchChange(q) {
			this.$emit('search-change', q)
		}
	},
	created() {
		if (this.opts) {
			this.options = this.opts
		}
	}
}
</script>
