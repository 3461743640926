<template>
  <div v-show="show" class="fixed bottom-0 inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center">
    <div v-show="show" @click="show = false" class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-400 opacity-75"></div>
    </div>
		<div id="modal-pax-list" class="flex flex-col bg-white border border-gray-300 rounded shadow z-10">
			<div class="flex">
				<div class="bg-blue-800 border-t-0 border-2 border-red-600 pl-1 pr-2 py-1 text-center rounded rounded-t-none flex items-center justify-start text-white font-bold text-xs">
					<img src="/img/loghi/micro.svg" class="w-4 mr-1" />
					{{ flight.takeOffDate }} ~ {{ flight.flightCode }}
				</div>
				<div class="flex-1 flex flex-col">
					<div class="border-t-4 border-blue-800" style="margin-left:-2px; height:1px">&nbsp;</div>
					<div class="border-t-4 border-red-600" style="height:1px">&nbsp;</div>
				</div>
			</div>
			<div class="bg-white rounded max-h-full text-center">
				<div class="m-4">
					<div class="flex justify-between mt-2">
						<div class="w-full flex flex-col items-start">
							<label class="italic text-xs mb-2 font-semibold text-blue-700">Lista passeggeri</label>
							<div class="w-full overflow-x-scroll md:overflow-x-hidden">
								<table class="w-full font-normal text-left border" cellspacing="0">
									<thead>
										<tr class="text-left border-b-2 bg-blue-200 text-blue-600 text-xs">
											<th class="border px-2">LID</th>
											<th class="border px-2">cod.pren.</th>
											<th class="border px-2">biglietto</th>
											<th class="border px-2">partenza</th>
											<th class="border px-2">destinazione</th>
											<th class="border px-2" colspan="3">passeggero</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="! paxes.length">
											<td class="px-2 py-1" colspan="8">
												Nessun passeggero su questo volo
											</td>
										</tr>
										<tr v-for="pax in paxes" :key="pax.id" style="background-color:rgba(5, 122, 212, 0.05)">
											<td class="px-2 py-1" v-text="pax.flightCode"></td>
											<td class="px-2 py-1" v-text="pax.booking"></td>
											<td class="px-2 py-1" v-text="pax.ticket"></td>
											<td class="px-2 py-1 whitespace-no-wrap" v-text="pax.origin"></td>
											<td class="px-2 py-1 whitespace-no-wrap" v-text="pax.destination"></td>
											<td class="px-2 py-1 whitespace-no-wrap">
												<span v-if="pax.id != 100">
													{{ pax.passenger }}
												</span>
												<span v-if="pax.id == 100">
													<fa class="x-dog text-xl" v-tippy="{ arrow: true }" content="animale domestico" />
												</span>
											</td>
											<td class="px-2 py-1 whitespace-no-wrap">
												<span v-if="pax.id != 100">
													{{ pax.category }} {{ pax.gender }}
												</span>
											</td>
											<td class="px-2 py-1">
												<fa v-if="pax.id != 100" class="s-info-circle" :content="pax.address" v-tippy="{ arrow: true }" />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="flex justify-between mt-8">
						<button @click.prevent="show = false" type="button" class="w-20 flex justify-center items-center bg-red-500 hover:bg-red-600 text-white text-sm font-semibold p-1 rounded">
							<fa class="s-times mr-2" />
							chiudi
						</button>
						<a :href="'/admin/pdf/liste-imbarco/' + flightDateDashed" target="_blank" class="w-48 flex justify-center items-center bg-green-500 hover:bg-green-600 text-white text-sm font-semibold p-1 rounded">
							<fa class="r-file-pdf mr-2" />
							vedi pdf lista imbarco
						</a>
					</div>
				</div>
			</div>
		</div>			
	</div>
</template>

<script>
export default {
	props: {
		flight: {
			type: Object
		}
	},
	data:() => ({
		show: false,
		paxes: []
	}),
	computed: {
		flightDateDashed() {
			return moment(this.flight.takeOffDate, 'DD/MM/YYYY').format('DD-MM-YYYY')
		}
	},
	methods: {
		open() {
			this.loadPaxList()
		},
		close() {
			this.show = false
		},
		async loadPaxList() {
			try {
				this.$emit('loading', true)
				let { data } = await api.get('api.flights.pax-list', { flightId: this.flight.flightId })
				this.paxes = data.paxes
				this.show = true
				this.$emit('loading', false)
			}
			catch(error) {
				callErrors(error)
				this.$emit('loading', false)
			}
		}
	}
}
</script>
