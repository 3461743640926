
<template>
  <div class="relative right-0 flex items-center" style="width:56px">

    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed h-full w-full bg-gray-600 cursor-default opacity-25" style="z-index:2; top:65px; left:0px; right:0px;"></button>

    <div class="h-full flex items-center select-none"> <!--  @click="isOpen = ! isOpen" -->
      <button v-if="user.id" @click="isOpen = ! isOpen" style="border-color:rgb(60, 141, 188)" class="mx-2 bg-blue-800 rounded-full border-2 w-10 h-10 text-base flex justify-center items-center font-semibold text-white focus:outline-none focus:shadow-outline active:bg-gray-900">

        <fa v-if="! user.verified || (user.is_agency && ! user.is_agency_auth)" class="r-clock" />

        <span v-if="user.verified && ! user.is_agency" v-text="initials" />

        <fa v-if="user.is_agency && user.is_agency_auth" class="s-globe" />

        <img v-if="roles.includes('Admin')" class="absolute" style="top:-3px; right:-3px" src="/img/ali-micro-admin.png" />
        <img v-else-if="roles.includes('PrenotaFull') || roles.includes('Prenotazioni') || roles.includes('CheckIn')" src="/img/ali-micro.png" class="absolute" style="top:-8px; right:-3px" />
      </button>

      <fa v-if="user.is_agency && user.is_agency_auth" @click.native="isOpen = ! isOpen" class="s-caret-down cursor-pointer -ml-2" />

      <button v-if="! user.id" @click="isOpen = ! isOpen" style="border-color:rgb(60, 141, 188)" class="text-blue-800 mx-2 bg-transparent w-10 h-10 text-base flex justify-center items-center font-semibold focus:outline-none">
        <fa class="s-sign-in-alt text-2xl" />
      </button>

      <transition enter-class="opacity-0 scale-90" enter-active-class="ease-out transition-fastest" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-active-class="ease-in transition-fastest" leave-to-class="opacity-0 scale-90">
        <div v-if="isOpen" class="mt-2 absolute origin-top-right text-left" style="top:45px; right:-8px; z-index:3;">

          <!-- user logged -->
          <div v-if="user.id" class="w-64 bg-white shadow-lg">
            <div class="flex flex-col justify-center p-3">
              <p v-if="user.verified" class="font-semibold text-gray-900 leading-none flex">
                {{ fullname }}
                <fa v-if="user.is_agency_auth" class="x-verified ml-1 relative" style="top:-3px" content="verified" v-tippy="{ arrow: true }" />
              </p>
              <p v-else class="font-semibold text-gray-900 leading-none" v-html="email" />

              <p v-if="label_pending_email_verification" class="font-semibold text-gray-900 leading-none mt-2" v-html="label_pending_email_verification" />
              <p v-if="label_pending_agency_auth" class="font-semibold text-gray-900 leading-none mt-2" v-html="label_pending_agency_auth" />
              <router-link :to="{ name: 'manage-account' }" @click.native="goTo({ url: '/account' })" class="text-xs text-gray-600 leading-none hover:underline mt-4">
                <span v-if="lang == 'it'">gestisci account</span>
                <span v-if="lang == 'en'">manage account</span>
              </router-link>
            </div>
            <div v-if="user.id && user.pax && ! isDashboardAllowed" class="border-t-2 border-gray-200 py-1 text-sm">
              <router-link :to="{ name: 'my-tickets' }" @click.native="goTo({ url: '/my-tickets' })" class="block px-3 py-1 leading-tight hover:bg-gray-200">I miei biglietti</router-link>
            </div>
            <div v-if="isDashboardAllowed" class="w-full flex flex-col items-baseline pt-0 p-3 mb-1">
              <label class="text-xs bg-blue-200 px-2 rounded-t">ADMIN</label>
              <div class="w-full flex flex-col border">
                <!--  :class="{ 'border-t border-gray-300': ! [1,4].includes(route.id) }" -->
                <div class="text-base border-t border-gray-300" v-for="route in routes" :key="route.id">
                  <router-link v-if="! route.hasOwnProperty('children')" :to="{ name: route.name }" @click.native="goTo(route)" class="block py-2 px-3 hover:bg-gray-200" v-text="route.label" />
                  <div v-else class="border-t-0 border-gray-300 text-base cursor-pointer" @click="subMenuOpen = ! subMenuOpen">
                    <span class="block py-1 px-3 pointer-events-none">
                      <fa :class="{ 's-caret-down': ! subMenuOpen, 's-caret-up': subMenuOpen }" />
                      Programmazione voli
                    </span>
                    <div v-if="subMenuOpen" v-for="subRoute in route.children" :key="subRoute.id" class="border-t border-gray-200 text-base pl-5 hover:bg-gray-200">
                      <router-link :to="{ name: subRoute.name }" @click.native="goTo(subRoute)" class="block py-2 px-3" v-text="subRoute.label" />
                    </div>
                  </div>
                </div>
                <div v-if="isDashboardAllowed" class="text-base border-t border-gray-300">
                  <a href="javascript:;" @click="showModal" class="py-2 px-3 hover:bg-gray-200 router-link-active flex">
                    <fa class="r-envelope text-sm mr-2" />
                    Invia mail informativa
                  </a>
                </div>
                <div v-if="isDashboardAllowed && roles.includes('PrenotaFull')" class="text-base border-t border-gray-300">
                  <a href="/admin/sms" class="py-2 px-3 hover:bg-gray-200 router-link-active flex">
                    <fa class="r-envelope text-sm mr-2" />
                    Invia SMS
                  </a>
                </div>
              </div>
            </div>
            <div class="border-t-2 border-gray-200 py-1 text-sm">
              <button type="button" @click="logout" class="w-full text-left block px-3 py-2 leading-tight hover:bg-gray-200">
                <fa class="s-power-off text-red-600" />
                Logout
              </button>
            </div>
          </div>

          <!-- guest -->
          <div v-if="! user.id" class="w-32 bg-white shadow-lg">
            <div class="p-1">
              <a href="/login" class="block px-3 py-3 leading-tight hover:bg-gray-200 border-b">
                <span v-if="lang == 'it'">Accedi</span>
                <span v-if="lang == 'en'">Login</span>
              </a>
              <a href="/sign-up" class="block px-3 py-3 leading-tight hover:bg-gray-200">
                <span v-if="lang == 'it'">Registrati</span>
                <span v-if="lang == 'en'">Sign up</span>
              </a>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <modal-info-mail-preview ref="modal" />
    <!-- <modal-info-mail-message-preview ref="modalMailMessage" /> -->
  </div>
</template>

<script>
import ModalInfoMailPreview from '../../common/components/ModalInfoMailPreview'
// import ModalInfoMailMessagePreview from '../../common/components/ModalInfoMailMessagePreview'

export default {
  name: 'user-dropdown',
  components: {
    ModalInfoMailPreview
    // ModalInfoMailMessagePreview
  },
  data:() => ({
    isOpen: false,
    subMenuOpen: false,
    allowedRoles: [],
    routes: []
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
    user() {
      return this.$parent.auth.user || null
    },
		roles() {
			if (this.$parent.auth.user) {
				return this.$parent.auth.user.roles.map(r => r.label)
			}
			else {
				return []
			}
		},
    initials() {
      if (! this.user || this.user.pax == null) {
        return null
      }
      else {
        let { pax: { firstname, lastname } } = this.user
        return firstname.substr(0, 1) + lastname.substr(0, 1)
      }
    },
    fullname() {
      let { pax } = this.user
      if (pax) {
        let { firstname, lastname } = pax
        if (! this.user.is_agency) {
          return `${firstname} ${lastname}`
        }
        else {
          return `${lastname}`
        }
      }
      else {
        return null
      }
    },
    email() {
      let { email } = this.user
      return email
    },
    label_pending_email_verification() {
      if (! this.user.verified) {
        return `<span class="italic font-normal text-red-600">account in attesa di <a href="/pending" class="underline">conferma email</a></span>`
      }
      else {
        return null
      }
    },
    label_pending_agency_auth() {
      if (this.user.is_agency && ! this.user.is_agency_auth) {
        return `<span class="italic font-normal text-red-600">agenzia in attesa di attivazione</span>`
      }
      else {
        return null
      }
    },
    isDashboardAllowed() {
      return !! this.user.id && this.checkRoles(this.allowedRoles)
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$parent.sidebar = false
        this.$parent.$refs['ato-drop'].isOpen = false
        this.$parent.$refs['flags-drop'].isOpen = false
      }
      if (! val) {
        this.subMenuOpen = false
      }
    }
  },
  methods: {
    showModal() {
      this.$refs.modal.show = true
      this.$refs.modal.form.email = null
      this.isOpen = false
    },
    // showModalMailMessage() {
    //   this.$refs.modalMailMessage.show = true
    //   this.$refs.modalMailMessage.form.email = null
    //   this.isOpen = false
    // },
    goTo(route) {
      if (! location.href.includes('/admin')) {
        location.href = location.origin + route.url
      }
      this.isOpen = false
    },
    async getUserData() {
      try {
        let { data } = await api.get('api.user-data')
        this.$parent.auth.user.id = data.user.id
        this.$parent.auth.user.basic = data.user.basic
        this.$parent.auth.user.email = data.user.email
        this.$parent.auth.user.verified = data.user.verified
        this.$parent.auth.user.is_agency = data.user.is_agency
        this.$parent.auth.user.is_agency_auth = data.user.is_agency_auth
        this.$parent.auth.user.pax = data.user.pax
        this.$parent.auth.user.roles = data.user.roles

        EventBus.$emit('user-ready')

        this.allowedRoles = data.allowed_roles
        this.routes = data.routes
      }
      catch(error) {
        callErrors(error)
      }
    },
		checkRoles(roles) {
      if (! roles) { roles = [] }
      return this.roles.filter(x => roles.includes(x)).length
		},
    logout() {
      this.isOpen = false
      this.$parent.sidebar = false

      this.$router.push({ name: 'logout' })
    },
    async checkUser() {
      try {
        await axios.get('/user-check')
        this.$parent.isLogged = true
        this.getUserData()
      }
      catch(error) {
        // callErrors(error)

        function getParentUrl() {
          var isInIframe = (parent !== window),
            parentUrl = null

          if (isInIframe) {
            parentUrl = document.referrer
          }

          return parentUrl
        }

        this.$parent.isLogged = false

        let urlSegments = ['/booking', '/admin']
        let parentWindowUrl = getParentUrl()
        let currentWindowUrl = location.href

        if (error.response.status == 401 && ((parentWindowUrl && urlSegments.find(x => parentWindowUrl.includes(x))) || urlSegments.find(x => currentWindowUrl.includes(x))) && ! location.href.includes('/booking-status')) {
          if (parentWindowUrl) {
            window.top.location.href = '/login'
          }
          else {
            notifyI('Sessione non attiva. Reindirizzamento al login...')
            setTimeout(x => location.href = '/login', 2000)
          }
          return
        }
      }
    }
  },
  created() {
    if (this.$parent.auth.user.id) {
      return
    }
    this.checkUser()
  }
}
</script>
