
<template>
  <div class="relative h-full py-1 flex items-center flex-1/6 no-underline px-5 font-semibold hover:bg-white _hover:text-blue-500 focus:outline-none">

    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed h-full w-full bg-gray-600 cursor-default opacity-25" style="z-index:2; top:65px; left:0px; right:0px;"></button>

    <div class="w-full h-full flex justify-center items-center cursor-pointer">

      <div @click="isOpen = ! isOpen" class="uppercase select-none w-full h-full cursor-pointer flex justify-center items-center">
        <span v-if="lang == 'it'">
          Scuola Volo
        </span>
        <span v-if="lang == 'en'">
          Flight Academy
        </span>
        <fa class="s-caret-down ml-1" />
      </div>

      <transition enter-class="opacity-0 scale-90" enter-active-class="ease-out transition-fastest" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-active-class="ease-in transition-fastest" leave-to-class="opacity-0 scale-90">
        <div v-if="isOpen" class="mt-2 absolute text-left" style="top:37px; left:0px; z-index:3">

          <div class="bg-white shadow-lg">
            <div class="p-1">
              <a href="/company/training" class="whitespace-no-wrap block px-3 py-3 leading-tight hover:bg-gray-200 border-b">
                <span v-if="lang == 'it'">Centro di addestramento</span>
                <span v-if="lang == 'en'">Approved Training Organization</span>
              </a>
              <a href="/company/tea-centre" class="whitespace-no-wrap block px-3 py-3 leading-tight hover:bg-gray-200 border-b">
                <span v-if="lang == 'it'">Centro per esami TEA</span>
                <span v-if="lang == 'en'">Centre for TEA examinations</span>
              </a>
              <a href="/company/finanzia-il-tuo-addestramento" class="whitespace-no-wrap block px-3 py-3 leading-tight hover:bg-gray-200">
                <span v-if="lang == 'it'">Finanzia il tuo addestramento</span>
                <span v-if="lang == 'en'">[ITA] Finanzia il tuo addestramento</span>
              </a>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ato-dropdown',
  data:() => ({
    isOpen: false
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
    user() {
      return this.$parent.auth.user || { user: null }
    },
		roles() {
			if (this.$parent.auth.user) {
				return this.$parent.auth.user.roles
			}
			else {
				return []
			}
		},
    initials() {
      if (! this.user) {
        return ''
      }
      else {
        let { firstname, lastname } = this.user
        return firstname.substr(0, 1) + lastname.substr(0, 1)
      }
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$parent.$refs['user-drop'].isOpen = false
        this.$parent.$refs['flags-drop'].isOpen = false
      }
    }
  },
  methods: {
		changeLang(lang) {
      this.$root.$children[0].sidebar = false
      this.$root.$children[0].lang = lang
      localStorage.setItem('lang', lang)
      this.isOpen = false
      window.scrollTo(0,0)
    },
		flagStyle(ln) {
			// if (ln !== this.lang) {
			// 	return {
			// 		filter: 'grayscale(100%)'
			// 	}
			// }
		}
  }
}
</script>
