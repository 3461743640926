<template>
  <div v-show="show" class="fixed bottom-0 inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center">
    <div v-show="show" class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div class="w-full">
        <vue-loading :loading="loading" />
        <div class="flex">
          <div class="bg-blue-800 border-t-0 border-2 border-red-600 pl-1 pr-2 py-1 text-center rounded rounded-t-none flex items-center justify-start text-white font-bold text-xs">
            <img src="/img/loghi/micro.svg" class="w-4 mr-1" />
            Passeggero
            <span class="font-normal text-gray-400 ml-2">• {{ form.lastname }} {{ form.firstname }}</span>
            <span v-if="hasAnyRole('Admin|PrenotaFull')" class="font-normal text-gray-400 ml-2">• #{{ form.id }}</span>
          </div>
          <div class="flex-1 flex flex-col">
            <div class="border-t-4 border-blue-800" style="margin-left:-2px; height:1px">&nbsp;</div>
            <div class="border-t-4 border-red-600" style="height:1px">&nbsp;</div>
          </div>
        </div>
        <div class="bg-white rounded max-h-full text-center">
          <div class="m-4">
            <div v-if="hasAnyRole('Admin|PrenotaFull')" class="flex flex-col md:flex-row justify-between mt-2">
              <div class="w-full md:w-1/2 pr-2 flex flex-col items-start">
                <label class="italic text-xs text-blue-700">cognome</label>
                <div class="w-full flex items-center mb-1 rounded border border-white">
                  <div class="w-full flex items-center">
                    <input v-model="form.lastname" type="text" class="w-full px-1 py-1 border border-gray-400 rounded">
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 flex flex-col items-start mt-2 md:mt-0">
                <label class="italic text-xs text-blue-700">nome</label>
                <div class="w-full flex items-center mb-1 rounded border border-white">
                  <div class="w-full flex items-center">
                    <input v-model="form.firstname" type="text" class="w-full px-1 py-1 border border-gray-400 rounded">
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="flex flex-col items-start">
                <label class="italic text-xs text-blue-700">data di nascita</label>
                <vue-datepicker v-model="form.birthdate" />
              </div>
            </div>
            <div class="flex justify-between mt-4">
              <div class="w-full flex">
                <div class="w-1/3 flex flex-col items-start">
                  <label class="italic text-xs text-blue-700">tipo documento</label>
                </div>
                <div class="w-2/3 flex flex-col items-start">
                  <label v-for="docType in docTypes" :key="docType.id" class="cursor-pointer flex items-center mr-5 rounded p-1 border border-white" :class="{ 'bg-green-100 border-green-300': form.docType == docType.id }">
                    <vue-radio v-model="form.doc_type" color="green" :value="docType.id" />
                    <div class="flex items-center text-xs ml-2">
                      <div>{{ docType.txt }}</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-4">
              <div class="w-full flex">
                <div class="w-1/3 flex flex-col items-start">
                  <label class="italic text-xs text-blue-700">num. documento</label>
                </div>
                <div class="w-2/3 flex flex-col items-start">
                  <input v-model="form.doc_num" type="text" class="w-full px-1 py-1 border border-gray-400 rounded">
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-4">
              <div class="w-full flex">
                <div class="w-1/3 flex flex-col items-start">
                  <label class="italic text-xs text-blue-700">scad. documento</label>
                </div>
                <div class="w-2/3 flex flex-col items-start">
                  <vue-datepicker v-model="form.doc_expdate" />
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="w-full flex flex-col items-start">
                <label class="italic text-xs text-blue-700">indirizzo</label>
                <div class="w-full flex items-center mb-1 rounded border border-white">
                  <div class="w-full flex items-center">
                    <input v-model="form.address" type="text" class="w-full px-1 py-1 border border-gray-400 rounded">
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col md:flex-row justify-between mt-2">
              <div class="w-full md:w-1/2 pr-2 flex flex-col items-start">
                <label class="italic text-xs text-blue-700">email</label>
                <div class="w-full flex items-center mb-1 rounded border border-white">
                  <div class="w-full flex items-center">
                    <input v-model="form.emailPax" type="text" class="w-full px-1 py-1 border border-gray-400 rounded">
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 flex flex-col items-start mt-2 md:mt-0">
                <label class="italic text-xs text-blue-700">telefono</label>
                <div class="w-full flex items-center mb-1 rounded border border-white">
                  <div class="w-full flex items-center">
                    <input v-model="form.telPax" type="text" class="w-full px-1 py-1 border border-gray-400 rounded">
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-8">
              <button @click="closeModal" class="flex justify-between items-center px-2 bg-red-600 hover:bg-red-700 text-white text-sm font-semibold p-1 rounded">
                <fa class="s-times mr-2" />
                chiudi
              </button>
              <button @click="submit" class="flex justify-between items-center px-2 w-24 bg-green-600 hover:bg-green-700 text-white text-sm font-semibold p-1 rounded">
                <fa class="s-save" />
                conferma
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-pax',
  data:() => ({
    show: false,
    loading: false,
    form: {
      id: null,
      firstname: null,
      lastname: null,
      birthdate: null,
      doc_type: null,
      doc_num: null,
      doc_expdate: null,
      address: null,
      emailPax: null,
      telPax: null
    },
    docTypes: [
      {
        id: 1,
        txt: 'carta d\'identità'
      },
      {
        id: 2,
        txt: 'passaporto'
      }
    ]
  }),
  methods: {
    hasAnyRole(roles) {
      return this.$parent.$parent.hasAnyRole(roles)
    },
    async submit() {
      try {
        this.loading = true

        if (
          this.form.birthdate != moment(this.form.birthdate, 'DD/MM/YYYY').format('DD/MM/YYYY')
          ||
          this.form.doc_expdate != moment(this.form.doc_expdate, 'DD/MM/YYYY').format('DD/MM/YYYY')
        )
        {
          this.loading = false
          notifyW('controllare data nascita e scad.documento')
          return
        }

        let { form } = this

        let { data } = await api.put(route('api.paxes.update', { pax: form.id }), form)

        this.loading = false

        if (! data) {
          notifyW('Data di nascita non compatibile con biglietti già emessi.')
        }
        else {
          notifyS('Informazioni salvate!')

          this.$parent.search(true)

          this.show = false
        }
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    },
    closeModal() {
      this.form.birthdate = null
      this.form.doc_expdate = null
      this.show = false
    }
  },
  created() {
    // EventBus.$on('prepare-modal-pax-update', ({ form }) => {
    //   this.form = Object.assign(this.form, form)

    //   this.$nextTick(x => {
    //     this.show = true
    //   })
    // })
  }
}
</script>
