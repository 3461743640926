<template>
	<label>
		<!-- <input type="checkbox" style="width:1.2em;height:1.2em" v-model="internalValue" :checked="model" :disabled="disabled"> -->
		<input type="checkbox" class="form-checkbox" v-model="internalValue" :checked="model" :disabled="disabled" style="width:1.5em; height:1.5em" :style="cssVars">
	</label>
</template>

<script>
export default {
	name: 'VueCheckbox',
	model: {
		prop: 'model',
		event: 'change'
	},
	props: {
		model: {
			type: Boolean,
			default: undefined
		},
		disabled: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: '#4299e1'
		}
	},
	data:() => ({
		internalValue: false
	}),
	computed: {
		cssVars() {
			return {
				'--color': this.color 
			}
		}
	},
	watch: {
		model(val) {
			if (val != this.internalValue) {
				this.internalValue = val
			}
		},
		internalValue(val) {
			if (val != this.model) {
				this.$emit('change', val)
			}
		}
	},
	created() {
		if (this.model) {
			this.internalValue = true
		}
	}	
}
</script>

<style scoped>
input.form-checkbox {
	color: var(--color);
}
</style>