
<template>
  <div class="relative right-0 flex items-center" style="width:56px">

    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed h-full w-full bg-gray-600 cursor-default opacity-25" style="z-index:2; top:65px; left:0px; right:0px;"></button>

    <div class="w-full h-full flex justify-center items-center cursor-pointer">

      <div @click="isOpen = ! isOpen" class="w-full h-full cursor-pointer flex justify-center items-center">
        <button v-if="lang == 'it'" type="button">
          <img alt="English" src="/img/flags/ita.jpg">
        </button>
        <button v-if="lang == 'en'" type="button">
          <img alt="English" src="/img/flags/eng.jpg">
        </button>
        <fa class="s-caret-down ml-1" />
      </div>

      <transition enter-class="opacity-0 scale-90" enter-active-class="ease-out transition-fastest" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-active-class="ease-in transition-fastest" leave-to-class="opacity-0 scale-90">
        <div v-if="isOpen" class="mt-2 absolute right-0 origin-top-right text-left" style="top:57px; z-index:3">
          <div class="w-32 bg-white shadow-lg">
            <div class="flex flex-col items-center justify-center p-1">
              <button class="w-full flex justify-center items-center border-b py-3" type="button" @click="changeLang('it')">
                <img class="mr-2" alt="English" src="/img/flags/ita.jpg" :style="flagStyle('it')">
                italiano
              </button>
              <button class="w-full flex justify-center items-center py-3" type="button" @click="changeLang('en')">
                <img class="mr-2" alt="English" src="/img/flags/eng.jpg" :style="flagStyle('en')">
                english
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'flags-dropdown',
  data:() => ({
    isOpen: false
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
    user() {
      return this.$parent.auth.user || { user: null }
    },
		roles() {
			if (this.$parent.auth.user) {
				return this.$parent.auth.user.roles
			}
			else {
				return []
			}
		},
    initials() {
      if (! this.user) {
        return ''
      }
      else {
        let { firstname, lastname } = this.user
        return firstname.substr(0, 1) + lastname.substr(0, 1)
      }
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$parent.$refs['ato-drop'].isOpen = false
        this.$parent.$refs['user-drop'].isOpen = false
      }
    }
  },
  methods: {
		changeLang(lang) {
      this.$root.$children[0].sidebar = false
      this.$root.$children[0].lang = lang
      localStorage.setItem('lang', lang)
      this.isOpen = false
      window.scrollTo(0,0)
    },
		flagStyle(ln) {
			// if (ln !== this.lang) {
			// 	return {
			// 		filter: 'grayscale(100%)'
			// 	}
			// }
		}
  }
}
</script>
