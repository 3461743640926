<template>
  <div v-show="show" class="fixed bottom-0 inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center">
    <div v-show="show" class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>    
    <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div class="w-full">
        <vue-loading :loading="loading" />
        <div class="flex">
          <div class="bg-blue-800 border-t-0 border-2 border-red-600 pl-1 pr-2 py-1 text-center rounded rounded-t-none flex items-center justify-start text-white font-bold text-xs">
            <img src="/img/loghi/micro.svg" class="w-4 mr-1" />
            Ticket
            <span class="font-normal text-gray-400 ml-2">• {{ form.ticketNr }}</span>
          </div>
          <div class="flex-1 flex flex-col">
            <div class="border-t-4 border-blue-800" style="margin-left:-2px; height:1px">&nbsp;</div>
            <div class="border-t-4 border-red-600" style="height:1px">&nbsp;</div>
          </div>
        </div>
        <div class="bg-white rounded max-h-full">
          <div class="m-4">
            <div class="flex justify-between mt-2">
              <div class="flex flex-col items-start">
                <label class="italic text-xs mb-2 font-semibold text-blue-700">tipo invio</label>
                <div class="flex items-center mb-1 rounded p-1 border border-white" :class="{ 'bg-green-100 border-green-300': form.pdfType == 0 }">
                  <vue-radio v-model="form.pdfType" color="green" :value="0" />
                  <div class="flex items-center text-xs ml-2">
                    <div>intera prenotazione</div>
                    <div class="ml-1 font-bold" v-text="form.bookingCode" />
                  </div>
                </div>
                <div class="flex items-center mb-1 rounded p-1 border border-white" :class="{ 'bg-green-100 border-green-300': form.pdfType == 1 }">
                  <vue-radio v-model="form.pdfType" color="green" :value="1" />
                  <div class="flex items-center text-xs ml-2">
                    <div>singolo ticket</div>
                    <div class="ml-1 font-bold" v-text="form.ticketNr" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-4">
              <div class="flex flex-col items-start w-full">
                <label class="italic text-xs mb-2 font-semibold text-blue-700">destinatario</label>
                <div class="flex items-center mb-1 rounded p-1 border border-white" :class="{ 'bg-green-100 border-green-300': form.recipientType == 0 }">
                  <vue-radio v-model="form.recipientType" color="green" :value="0" />
                  <div class="flex items-center text-xs ml-2">
                    <div class="text-gray-500">email prenotazione</div>
                    <div class="ml-2 font-bold" v-text="form.emailBooking" />
                  </div>
                </div>
                <div v-if="form.emailPax" class="flex items-center mb-1 rounded p-1 border border-white" :class="{ 'bg-green-100 border-green-300': form.recipientType == 1 }">
                  <vue-radio v-model="form.recipientType" color="green" :value="1" />
                  <div class="flex items-center text-xs ml-2">
                    <div class="text-gray-500">email pax anagrafe</div>
                    <div class="ml-2 font-bold" v-text="form.emailPax" />
                  </div>
                </div>
                <div class="w-full flex items-center mb-1 rounded p-1 border border-white" :class="{ 'bg-green-100 border-green-300': form.recipientType == 2 }">
                  <vue-radio v-model="form.recipientType" color="green" :value="2" />
                  <div class="w-full flex items-center text-xs ml-2">
                    <div class="text-gray-500 whitespace-no-wrap">altra email</div>
                    <input ref="emailCustom" v-model="form.emailCustom" @focus="form.recipientType = 2" @keyup="form.recipientType = 2" type="text" class="w-full ml-2 px-1 py-1 border border-gray-400 rounded" style="margin-top:2px">
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-4 pt-2 border-t border-b border-gray-400">
              <div class="flex flex-col items-start w-full">
                <label class="italic text-xs mb-2 font-semibold text-blue-700">modulistica</label>
                <div class="flex items-center mb-1 rounded p-1 border border-white">
                  <vue-checkbox v-model="form.pdfForms.minor" color="green" :value="0" />
                  <div class="flex items-center text-xs ml-2">
                    <div class="text-gray-500">
                      email dichiarazione trasporto minori (non accopagnati o accompagnati da non-genitore/non-tutore)
                      <span v-if="labels && labels.minor" class="italic text-blue-600" v-text="`(richiesto per questa prenotazione)`" />
                    </div>
                  </div>
                </div>
                <div class="flex items-center mb-1 rounded p-1 border border-white">
                  <vue-checkbox v-model="form.pdfForms.pregnant" color="green" :value="1" />
                  <div class="flex items-center text-xs ml-2">
                    <div class="text-gray-500">
                      dichiarazione_liberatoria_trasporto_gestanti
                      <span v-if="labels && labels.pregnant" class="italic text-blue-600" v-text="`(richiesto per questa prenotazione)`" />
                    </div>
                  </div>
                </div>
                <div class="flex items-center mb-1 rounded p-1 border border-white">
                  <vue-checkbox v-model="form.pdfForms.weapons" color="green" :value="2" />
                  <div class="flex items-center text-xs ml-2">
                    <div class="text-gray-500">
                      dichiarazione_trasporto_armi_munizioni
                      <span v-if="labels && labels.weapons" class="italic text-blue-600" v-text="`(richiesto per questa prenotazione)`" />                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-4">
              <button @click.prevent="show = false" type="button" class="w-20 flex justify-center items-center bg-red-600 hover:bg-red-700 text-white text-sm font-semibold p-1 rounded">
                <fa class="s-times mr-2" />
                chiudi
              </button>
              <button @click.prevent="confirm" type="button" class="flex justify-center items-center px-3 bg-green-600 hover:bg-green-700 text-white text-sm font-semibold p-1 rounded">
                <fa class="r-envelope mr-2" />
                invia email
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'modal-email',
  data:() => ({
    show: false,
    loading: false,
    form: {
      pdfType: 0,
      recipientType: 0,
      ticketNr: null,
      bookingCode: null,
      emailPax: null,
      emailBooking: null,
      emailCustom: null,
      pdfForms: {
        minor: false,
        pregnant: false,
        weapons: false
      }
    },
    labels: null,
    docTypes: [
      {
        id: 1,
        txt: 'carta d\'identità'
      },
      {
        id: 2,
        txt: 'passaporto'
      },
      {
        id: 4,
        txt: 'altro'
      }
    ]
  }),
  watch: {
    show(val) {
      if (! val) this.resetEmailModal      
    }
  },
  methods: {
    resetEmailModal() {
      this.form.email = {
        pdfType: 0,
        recipientType: 0,
        ticketNr: null,
        bookingCode: null,
        emailPax: null,
        emailBooking: null,
        emailCustom: null,
        pdfForms: {
          minor: false,
          pregnant: false,
          weapons: false
        }
      }
    },    
    async confirm() {
      try {
        let { form } = this

        if (form.recipientType == 2) {
          function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          }
          if (! validateEmail(form.emailCustom)) {
            notifyW('email non valida!')
            return
          }
        }        

        this.loading = true

        let { data } = await api.post('api.send-ticket', form)

        var recipient
        if (form.recipientType == 0) {
          recipient = form.emailBooking
        }
        else if (form.recipientType == 1) {
          recipient = form.emailPax
        }
        else if (form.recipientType == 2) {
          recipient = form.emailCustom
        }

        notifyS(`Email inviata a ${recipient}!`)
        
        this.loading = false

        this.show = false
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    }
  }
}
</script>
