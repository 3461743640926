<template>
  <i :class="iClass" :style="style" @click="clicked" />
</template>

<script>
export default {
  name: 'vue-icon',
  props: {
    iName: {
      type: String
    },
    iClass: {
      type: String,
      default: 'w-4 h-4'
    },
    iColor: {
      type: String,
      default: 'white'
    },
    iClick: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    src() {
      return '/img/svgs/' + this.iName + '.svg'
    },
    style() {
      return {
        'display': 'inline-block',
        'background-color': this.iColor, /* defines the background color of the image */
        'mask': 'url(' + this.src + ') no-repeat center / contain',
        '-webkit-mask': 'url(' + this.src + ') no-repeat center / contain'
      }
    }
  },
  methods: {
    clicked() {
      if (! this.iClick) { return }
      this.$emit('clicked')
    }
  }
}
</script>
