<template>
  <span :class="classes" class="icon-fa">
    <svg role="img" :class="classes">
      <use href="/img/svgs/font-awesome.svg#empty" />
    </svg>    
  </span>
</template>

<script>
let svg4everybody = require('../../svg4everybody.min.js')

export default {
  name: 'font-awesome',
  data:() => ({
    icon: 'empty',
    observer: null
  }),
  computed: {
    randomClass() {
      return 'vue-fa-' + uuidGen().substr(0, 8)
    },
    classes() {
      return [this.randomClass, 'fill-current']
    }
  },
  watch: {
    icon(val, oldVal) {
      this.setHref(val, oldVal)
    }
  },
  methods: {
    setHref(val, oldVal) {
      let svgUse = this.$el.children[0].children[0]
      let svgUseHref = svgUse.getAttribute('href')
      svgUseHref = svgUseHref.replace(oldVal, val)
      // console.log(svgUseHref)
      svgUse.setAttribute('href', svgUseHref)      
      svg4everybody()
    },
    setIcon(iconClass) {
      this.icon = iconClass
    },
    checkSpinClass() {
      let containerClasses = this.$el.classList
      let svgClasses = this.$el.children[0].classList
      if (containerClasses.contains('fa-spin')) {
        // remove from parent container
        this.$el.classList.remove('fa-spin')

        if (! svgClasses.contains('fa-spin')) {
          // add to child svg
          this.$el.children[0].classList.add('fa-spin')
        }
      }
    },
    mountSVG() {
      let iconClass
      this.$el.classList.forEach(c => {
        if (c.startsWith('x-') || c.startsWith('b-') || c.startsWith('r-') || c.startsWith('s-')) {
          iconClass = c
        }
      })

      this.setIcon(iconClass)
    },
    mountObserver() {
      this.observer = new MutationObserver(mutations => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName)
          this.$nextTick(() => {
            var newClasses = newValue.split(' ')
            var oldClasses = m.oldValue.split(' ')
            let iconClass
            newClasses.forEach(c => {
              if (c.startsWith('b-') || c.startsWith('r-') || c.startsWith('s-')) {
                iconClass = c
              }
            })
            if (! oldClasses.includes(iconClass)) {
              this.mountSVG()
            }
          })
        }
      })
      this.observer.observe(this.$el, { //this.$refs.svg
        attributes: true,
        attributeOldValue : true,
        attributeFilter: ['class']
      })
    }
  },
  mounted() {    
    this.mountSVG()
    this.mountObserver()
  },
  beforeDestroy() {
    this.observer.disconnect()
  }
}
</script>
