
<template>
  <div class="relative">
    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed h-full w-full bg-gray-600 cursor-default opacity-25" style="z-index:2; top:65px; left:0px; right:0px;"></button>
    <div class="h-full flex items-center cursor-pointer" @click="isOpen = ! isOpen">
      <button class="border-r h-full py-1 flex items-center flex-1/6 uppercase no-underline px-5 font-semibold hover:text-blue-500 focus:outline-none" :class="dropdownMenuHighlight()">Programmazione<br>voli</button>     
      <transition enter-class="opacity-0 scale-90" enter-active-class="ease-out transition-fastest" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-active-class="ease-in transition-fastest" leave-to-class="opacity-0 scale-90">
        <div v-if="isOpen" class="mt-2 absolute origin-top-right text-left" style="top:42px; right:-8px; z-index:3;">         
          <div class="w-40 bg-white shadow-lg">
            <div class="border-t-2 border-gray-200 p-1 text-sm">
              <button type="button" @click="goTo('adm_flights-planner-manage')" class="w-full text-left px-3 py-3 leading-tight hover:bg-gray-200">Gestione</button>
              <button type="button" @click="goTo('adm_flights-planner-replace')" class="border-t w-full text-left px-3 py-3 leading-tight hover:bg-gray-200">Sostituzione</button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'manage-flights-dropdown',
  data:() => ({
    isOpen: false
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
    user() {
      return this.$parent.auth.user || null
    },
		roles() {
			if (this.$parent.auth.user) {
				return this.$parent.auth.user.roles
			}
			else {
				return []
			}
		},
    initials() {
      if (! this.user) {
        return ''
      }
      else {
        let { firstname, lastname } = this.user
        return firstname.substr(0, 1) + lastname.substr(0, 1)
      }
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$parent.$refs['flag-drop'].isOpen = false
      }
    }
  },
  methods: {
		dropdownMenuHighlight() {
			if (['adm_flights-planner-manage', 'adm_flights-planner-replace'].includes(this.$route.name)) {
				return 'bg-white'
			}
		},
		goTo(name) {
			let { isPublic } = this
			if (! isPublic && ! name.includes('adm_')) {
				if (name == 'home') { name = '' }
				location.href= `/${name}`
				return
			}
			dbg(name, '► ROUTE ◄')
			this.sidebar = false
			this.extendedCompany = false
			this.$router.push({ name })
    }
  }
}
</script>
