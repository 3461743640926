<template>
	<tr class="text-blue-800" :class="{ 'bg-blue-100': isSelected }" :style="{ 'color': ! flight.selectable ? '#b7b5b5' : '', 'text-decoration': ! flight.selectable ? '' : '' }">
		<td class="py-2 px-1 md:px-3 border-b border-gray-400">
			<vue-radio v-if="flight.selectable" class="block" v-model="selectedValue" :value="flight.flightId" :disabled="! flight.selectable" />
			<span v-else style="font-size:0.6rem" class="bg-red-500 text-white rounded px-1">
				N/D
			</span>
		</td>
		<td class="py-2 px-3 border-b border-gray-400">
			<div class="flex align-items">
				<span v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull']) && flight.animals" class="mr-1" v-tippy="{ arrow: true }" :content="`${flight.animals} ${flight.animals == 1 ? 'animale domestico' : 'animali domestici' } a bordo`">
					<fa class="x-dog text-xl" />
				</span>
				<span class="text-xs md:text-sm leading-normal" :class="{ 'bg-yellow-400 rounded p-1' : showExtraLabel() }" v-text="flight.flightCode"></span>
				<span class="ml-1 md:hidden">
					<fa class="s-info-circle" :content="flight.marks + ' → ' + flight.description" v-tippy="{ arrow: true }" />
				</span>
			</div>
		</td>
		<td v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="hidden md:table-cell py-2 px-3 border-b border-gray-400" v-text="flight.marks"></td>
		<td class="hidden md:table-cell py-2 px-3 border-b border-gray-400" v-html="flight.description_segments"></td>
		<td class="hidden md:table-cell py-2 px-3 border-b border-gray-400" v-text="flight.origin"></td>
		<td class="py-2 px-3 border-b text-xs md:text-sm border-gray-400" v-text="flight.takeOffTime"></td>
		<td class="hidden md:table-cell py-2 px-3 border-b border-gray-400" v-text="flight.destination"></td>
		<td class="py-2 px-3 border-b text-xs md:text-sm border-gray-400" v-text="flight.landingTime"></td>
		<td v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="py-2 px-3 border-b text-xs md:text-sm border-gray-400">
			<flight-pax-list
				:ref="'flight-pax-list-' + flight.flightId"
				:flight="flight"
				@loading="status => $emit('loading', status)" 
			/>
			<div class="w-full flex flex-col items-center">
				<div class="w-full flex justify-center">
					<div clas="w-1/2">{{ flight.seatsMax }}</div>
					<div clas="w-1/2">
						<button @click="openModalPaxList(flight)" type="button" class="btn btn-xs btn-azure" data-toggle="tooltip" title="lista passeggeri" style="padding: 0px 0px 0px 5px;">
							<fa class="s-list" />
						</button>
					</div>
				</div>
				<span style="font-size:0.65rem" class="text-gray-300 float-right">{{ flight.flightId }}</span>
			</div>
		</td>
	</tr>  
</template>


<script>
import FlightPaxList from './FlightPaxList'

export default {
	components: {
		FlightPaxList
	},
	props: {
		flight: {
			type: Object
		},
		idSelected: {
			type: Number|Object,
			default: null
		},
		routeType: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			selectedValue: 0
		}
	},
	computed: {
		logged() {
			return this.$parent.user.logged
		},
		isSelected() {
			return this.idSelected == this.selectedValue
		}
	},
	watch: {
		selectedValue(val) {
			if (val) {
				this.$emit('checked', { flightId: this.flight.flightId })
			}
		},
		idSelected: {
			handler (val) {
				if (val === this.flight.flightId) {
					this.selectedValue = this.flight.flightId
				}
				else {
					this.selectedValue = null
				}
			},
			immediate: true
		}
	},
	methods: {
		asd(loading) {
			dbg(loading)
		},
		openModalPaxList(flight) {
			var modalName = 'flight-pax-list-' + flight.flightId
			this.$refs[modalName].open()
		},
		showExtraLabel() {
			return this.flight.selectable && this.flight.extra && this.$parent[this.routeType + 'FlightsHaveExtra'] && this.checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])
		},
		checkRoles(roles) {
			return this.$parent.checkRoles(roles)
		}
	}
}
</script>
