import Vue from 'vue'
window.Vue = new Vue

require('./helpers.js')

window._ = require('lodash')

/*
--- Cleave ---
*/
import Cleave from 'cleave.js'
window.Cleave = Cleave

// reCAPTCHA
// -----> rimuovere commenti dal metodo "hideRecaptcha" su "App.vue"
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6LdSpNYUAAAAADvmGR_9vQb_qcKwlIFCUytUa1k8' })


window.$ = window.jQuery = require('jquery')
// include('../../public/js/plugins/rs/revolution.tools.min')
// require('../../public/js/plugins/rs/rs6.min')
// $.revolution = require('../../public/template/rs-plugin/js/jquery.themepunch.revolution.min')

window.lcb = 'background: #222; color: aqua; font-size: 18px; font-weight: bold'
window.lcg = 'background: #222; color: lime; font-size: 18px; font-weight: bold'
window.lcr = 'background: #222; color: red; font-size: 18px; font-weight: bold'
window.lcy = 'background: #222; color: yellow; font-size: 18px; font-weight: bold'

/* NOTIFY - IZITOAST */
// import iziToast from 'izitoast'// http://izitoast.marcelodolce.com/#Options
// import 'izitoast/dist/css/iziToast.min.css'

import AWN from 'awesome-notifications'
import 'awesome-notifications/dist/style.css'

class NotifyClass {

	get options() {
		return {
			class: 'web-notification',
			overlayColor: 'rgba(0, 0, 0, 1)',
			position: 'bottomRight',
			resetOnHover: true,
			transitionIn: 'flipInX',
			transitionOut: 'flipOutX'
		}
	}

	info(message, overrideOptions = {}) {
		// this.hide()
		var typeOptions = {
			title: 'Info',
			color: 'blue',
			message
		}
		return iziToast.show({ ...this.options, ...typeOptions, ...overrideOptions })
	}

	success(message, overrideOptions = {}) {
		// this.hide()
		var typeOptions = {
			title: 'Success!',
			color: 'green',
			message
		}
		return iziToast.show({ ...this.options, ...typeOptions, ...overrideOptions })
	}

	warning(message, overrideOptions = {}) {
		// this.hide()
		var typeOptions = {
			title: 'Warning!',
			color: 'yellow',
			message
		}
		return iziToast.show({ ...this.options, ...typeOptions, ...overrideOptions })
	}

	error(message, overrideOptions = {}) {
		// this.hide()
		var typeOptions = {
			title: 'Error!',
			color: 'red',
			message
		}
		return iziToast.show({ ...this.options, ...typeOptions, ...overrideOptions })
	}

	hide() {
		var openToasts = document.querySelector('.iziToast')
		if (openToasts !== null) {
			return iziToast.hide(document.querySelector('.iziToast'), { transitionOut: 'fadeOutUp' })
		}
	}
}

window.Notify = new NotifyClass

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

window.notify = function (type, text, opts) {
	let notificationPlugin = 'AWN'//'toast'

	var check = null
	try {
		check = JSON.parse(text)
	}
	catch (error) {
		check = 'set as string'
	}
	
	if (typeof check === 'object') {
		text = JSON.stringify(JSON.parse(text), null, 2);
	}

	let title = type.capitalize() + '!'

	// "Notify" notifications
	if (notificationPlugin === 'notify') {
		Vue.notify({
			type,
			duration: -1,
			title,
			text
		})
	}

	// "iziToast" notifications [options page http://izitoast.marcelodolza.com/#Start]
	if (notificationPlugin === 'toast') {
		if (type === 'warn') { type = 'warning' }
		if (! window.toastInstance) {
			window.toastInstance = new NotifyClass()
		}
		window.toastInstance[type](text, opts)
	}

	if (notificationPlugin === 'AWN') {
		if (type == 'error') { type = 'alert' }
		else if (type == 'warn') { type = 'warning' }
		let notifier = new AWN({
			icons: {
				success: 's-check',
				warning: 's-exclamation-triangle',
				info: 's-info-circle',
				alert: 's-times-circle',
				prefix: '<span class="text-2xl icon-fa"><svg role="img"><use href="/img/svgs/font-awesome.svg#',
				suffix: '"></use></svg></span>'
			},
			labels: {
				success: opts && opts.hasOwnProperty('title') ? opts.title : 'OPERAZIONE CONFERMATA!',
				warning: opts && opts.hasOwnProperty('title') ? opts.title : 'ATTENZIONE!',
				alert: opts && opts.hasOwnProperty('title') ? opts.title : 'ERRORE!',
				info: opts && opts.hasOwnProperty('title') ? opts.title : 'INFO'
			}
		})
		notifier[type](text)
	}
}
window.notifyS = function (text, opts = null) {
	window.notify('success', text, opts)
}
window.notifyE = function (text, opts = null) {
	window.notify('error', text, opts)
}
window.notifyW = function (text, opts = null) {
	window.notify('warn', text, opts)
}
window.notifyI = function (text, opts = null) {
	window.notify('info', text, opts)
}

require('icheck')
window.moment = require('moment')
// window.moment.locale('it')

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

var axios = require('axios')
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios = axios

window.SocketEventBus = new Vue()
SocketEventBus.$emit('connected')

import api from './api'
window.api = api

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VModal from 'vue-js-modal'
Vue.use(VModal)

import VueTippy, { TippyComponent } from 'vue-tippy'
Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('treeselect', Treeselect)
